import React, {useState} from "react";
import {queryString} from "utils/query";
import {BoxButton, ButtonGroup, Divider, Input} from "components/atoms";
import {Layout, BottomSheet, BottomFloating, FieldTitle, Field, UseForm, TermsAllCheck, } from "components/organisms";
import {validAll} from "utils/validate";
import domain from "utils/domainLink";

export default function RegisterSocial(){
    /*** 로그인 유효성 검사 & 폼 전송 **/
    const [agreeComplete, setAgreeComplete] = useState<boolean>(false)

    const redirectUrl = queryString('redirectUrl').param;
    const id = queryString('id').param;
    const username = queryString('username').param;
    const socialType = queryString('socialType').param;
    const email = queryString('email').param;
    const fingerprint = queryString('fingerprint').param;

    const [validate, setValidate] = useState<boolean>(false);
    const initialValues = {redirectUrl: redirectUrl, socialType: socialType, username: username, id: id, email: email}
    const handleValidate = (values: any) => setValidate(validAll(values))
    const handleSubmit = () => {
        // const form = document.getElementById('form') as HTMLFormElement;
        // form.method = 'POST';
        // form.action = `${domain.main}/oauth/snsSignUp`;
    }

    /*** 약관동의 **/
    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)


    return (
        <>
            <Layout
                header={{use: true, logo: false, back: true, close: false, util: false, title: 'SNS 간편가입'}}
                container={{use: true}}
            >
                <Divider>
                    <UseForm method={"POST"} action={`${domain.main}/oauth/snsSignUp`} initialValues={initialValues} validate={handleValidate} onSubmit={handleSubmit} id={'form'}>
                        <FieldTitle>
                            <h2 className={'subtitle_20B'}>회원정보를 입력해 주세요</h2>
                        </FieldTitle>

                        <Field type="text" name="username" label={'이름'} placeholder={'이름을 입력해주세요.'} />
                        <Field type="text" name="email" label={'이메일 주소'} placeholder={'이메일 주소를 입력해주세요'} />

                        <Input type="hidden" name={'socialType'} value={socialType} />
                        <Input type="hidden" name={'redirectUrl'} value={redirectUrl} />
                        <Input type="hidden" name={'id'} value={id} />
                        <Input type="hidden" name={'fingerprint'} value={fingerprint} />

                        {/*modal*/}
                        <BottomSheet open={open} close={handleClose}>
                            <>
                                <h3 className={'subtitle_20B'}>
                                    간편가입을 위해<br/>
                                    아래 약관에 동의해주세요.
                                </h3>
                                <TermsAllCheck setComplete={setAgreeComplete} />
                                <ButtonGroup type={'vertical'}>
                                    <BoxButton
                                        tag={'Button'}
                                        type={'submit'}
                                        text={'가입 완료'}
                                        theme={{fill: 'solid', tone: 'primary', size: 'large'}}
                                        disabled={agreeComplete? false : true}
                                    />
                                </ButtonGroup>
                            </>
                        </BottomSheet>
                    </UseForm>
                </Divider>

                <BottomFloating>
                    <ButtonGroup type={'vertical'}>
                        <BoxButton
                            tag={'Button'}
                            text={'약관 동의'}
                            theme={{fill: 'solid', tone: 'primary', size: 'large'}}
                            disabled={validate? false : true}
                            onClick={handleOpen}
                        />
                    </ButtonGroup>
                </BottomFloating>
            </Layout>
        </>
    )
}