import React from "react";
import styles from "../Terms.module.scss";
import { cls } from "utils/utils";
import { openAppPage } from "utils/app";
import { tmapApp } from "utils/tmapApp";

export default function TmapTermsPrivacy() {
    return (
        <>
            <div className={cls(styles.terms, "mt24")}>
                <div className={styles.summary_terms}>
                    <p className={styles.sort_para}>
                        차란차 주식회사 (이하 "회사")는 통신비밀보호법,
                        전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에
                        관한 법률, 개인정보보호법 및 정부가 제정한
                        개인정보보호지침 등 정보통신서비스제공자가 준수하여야 할
                        관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에
                        의거한 개인정보취급방침을 정하여 이용자의 권익 보호에
                        최선을 다하고 있습니다. 회사의 개인정보취급방침은 다음과
                        같은 내용을 담고 있습니다.
                    </p>
                    <ol>
                        <li>1.수집하는 개인정보의 항목 및 수집방법</li>
                        <li>2.개인정보의 수집 및 이용방법</li>
                        <li>3.개인정보의 공유 및 제공</li>
                        <li>4.개인정보의 취급위탁</li>
                        <li>5.개인정보의 보유 및 이용기간</li>
                        <li>6.개인정보 파기절차 및 이용방법</li>
                        <li>7.만 14세 미만 아동의 개인정보 보호</li>
                        <li>8.이용자의 권리와 그 행사 방법</li>
                        <li>
                            9.개인정보 자동 수집 장치의 설치 / 운영 및 거부에
                            관한 사항
                        </li>
                        <li>10.개인정보의 기술적/관리적 보호 대책</li>
                        <li>11.개인정보 수집 및 이용동의 내차팔기 신청 시</li>
                        <li>12.개인정보관리책임자 및 담당자의 연락처</li>
                        <li>13.기타</li>
                        <li>14.고지의 의무</li>
                    </ol>
                </div>

                <ul className={styles.list_terms}>
                    {/*1. 수집하는 개인정보의 항목 및 수집 방법*/}
                    <li>
                        <span className={styles.sort}>
                            1. 수집하는 개인정보의 항목 및 수집 방법
                        </span>
                        <ul className={styles.list_depth}>
                            <li>
                                <span className={styles.sort}>
                                    가. 수집하는 개인정보의 항목
                                </span>
                                <p>
                                    회사는 회원가입, 원활한 고객상담, 각종
                                    서비스의 제공을 위해 아래와 같은 개인정보를
                                    수집하고 있습니다.
                                </p>
                                <div className={styles.sort_table}>
                                    <table>
                                        <caption className="blind">
                                            수집하는 개인정보의 항목
                                        </caption>
                                        <colgroup>
                                            <col style={{ width: "50%" }} />
                                            <col style={{ width: "50%" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">필수항목</th>
                                                <th scope="col">선택항목</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    닉네임, 사용자 서비스
                                                    이용기록, 쿠키
                                                </td>
                                                <td>이름, 휴대전화번호</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p>
                                    이벤트나 추가 회원정보 수집을 통해 이용자의
                                    관심분야에 대한 세부화된 항목의 정보는
                                    수집될 수 있습니다. 또한 서비스 이용과정이나
                                    사업 처리 과정에서 아래와 같은 정보들이
                                    생성되어 수집될 수 있습니다.
                                </p>
                                <ul>
                                    <li>
                                        -이용자의 브라우저 종류 및 OS,검색어,
                                        서비스 이용기록: 서비스 이용에 대한 통계
                                        분석
                                    </li>
                                    <li>
                                        -IP Address, 방문일시, 서비스 이용기록,
                                        불량이용기록, 쿠키: 불법/부정 이용방지
                                    </li>
                                    <li>-결제 기록: 전자금융거래 기록 보관</li>
                                </ul>
                                <p>
                                    회사는 서비스 제공에 필요한 최소한의
                                    개인정보만 수집하며, 이용자의 기본적 인권을
                                    침해할 우려가 있는 민감한 정보(인종, 종교,
                                    사상, 출신지, 본적지, 정치적 성향 및
                                    범죄기록, 건강상태 등)는 수집하지 않습니다.
                                    다만, 이용자가 수집에 동의하시는 경우는
                                    예외로 합니다.
                                    <br />
                                    이용자는 개인정보의 수집/ 대한 동의를 거부할
                                    수 있습니다. 다만, 이 경우 서비스 이용 및
                                    혜택 제공에 제한을 받을 수 있습니다. 또한,
                                    회원가입 시 필수 정보의 수집/이용 동의를
                                    거부할 경우 서비스 제공에 제한이 있습니다.
                                </p>
                            </li>
                            <li>
                                <span>나. 개인정보 수집 방법</span>
                                <p>
                                    회사는 다음과 같은 적법하고 공정한 방법으로
                                    개인정보를 수집합니다.
                                </p>
                                <ul>
                                    <li>
                                        -홈페이지, 고객센터, 이메일, 이벤트
                                        응모, 배송요청, 후기작성, 상담문의
                                    </li>
                                    <li>-협력 회사로부터의 제공</li>
                                    <li>-생성 정보 수집 툴을 통한 수집</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    {/*2. 개인정보의 수집 및 이용목적*/}
                    <li>
                        <span className={styles.sort}>
                            2. 개인정보의 수집 및 이용목적
                        </span>
                        <p className={styles.sort_para}>
                            회사는 수집한 개인정보를 다음의 목적을 위해
                            활용합니다.
                        </p>
                        <ul className={styles.list_depth}>
                            <li>
                                <span className={styles.sort}>
                                    가. 서비스 제공
                                </span>
                                <p>
                                    서비스 제공에 관한 계약 이행 및 서비스
                                    제공에 따른 요금정산, 머신 러닝 모델 및
                                    알고리즘 등의 당사 기술을 교육 및 개선,
                                    맞춤형 콘텐츠 제공, 물품배송 또는 청구서 등
                                    발송, 본인인증, 구매 및 판매연계 서비스를
                                    위한 본인 확인, 구매 및 요금 결제, 요금
                                    추심, 경품배송
                                </p>
                            </li>
                            <li>
                                <span className={styles.sort}>
                                    나. 회원관리
                                </span>
                                <p>
                                    회원제 서비스 이용 및 제한적 본인 확인제에
                                    따른 본인확인, 개인식별, 불량회원의 부정
                                    이용방지와 비인가 사용방지, 가입의사 확인,
                                    가입 및 가입횟수 제한, 분쟁 조정을 위한
                                    기록보존, 불만처리 등 민원처리, 고지사항
                                    전달
                                </p>
                            </li>
                            <li>
                                <span className={styles.sort}>
                                    다. 신규 서비스 개발 및 마케팅
                                </span>
                                <p>
                                    신규 서비스 개발 및 맞춤 서비스 제공,
                                    통계학적 특성에 따른 서비스 제공 및 광고
                                    게재, 서비스의 유효성 확인, 이벤트 및 광고성
                                    정보 제공, 접속빈도 파악, 이용자의
                                    서비스이용에 대한 통계,
                                    오프라인(직거래센터)에서의 매매업자 회원 및
                                    광고 관리
                                </p>
                            </li>
                            <li>
                                <span className={styles.sort}>
                                    라. 수집한 개인정보의 이용목적 구분
                                </span>
                                <span className={styles.sort}>공통</span>
                                <ul>
                                    <li>
                                        -아이디, 닉네임 : 회원제 서비스 이용에
                                        따른 본인 확인절차에 이용
                                    </li>
                                    <li>
                                        -이메일주소, 휴대전화번호 : 회원제
                                        서비스 이용에 따른 본인 확인절차에 이용,
                                        고지사항 전달 및 본인의사확인, 불만처리,
                                        이벤트 안내 등 고객 맞춤형 정보 서비스
                                        안내, 사용자 구분 등 원활한 의사소통
                                        경로의 확보
                                    </li>
                                    <li>
                                        -사용자의 서비스 이용기록, 쿠키 :
                                        플랫폼을 사용하여 콘텐츠를 기반으로 각
                                        사용자에게 맞춤형 상품, 서비스 등을
                                        제공하기 위하여 이용
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    {/*3. 개인정보의 공유 및 제공*/}
                    <li>
                        <span className={styles.sort}>
                            3. 개인정보의 제3자 정보 제공
                        </span>
                        <ul>
                            <li>
                                <p>
                                    회사는 이용자의 개인정보를 "2. 개인정보의
                                    수집 및 이용목적"에서 고지한 범위 내에서
                                    사용하며, 이용자의 사전 동의 없이는 동
                                    범위를 초과하여 이용하거나 원칙적으로
                                    이용자의 개인정보를 외부에 공개하지
                                    않습니다. 다만, 아래의 경우에는 예외로
                                    합니다
                                </p>
                                <ul>
                                    <li>
                                        -이용자가 사전에 공개에 동의하였거나
                                        서비스 제공 관련 계약 이행을 위하여
                                        필요한 개인정보로 통상의 동의를 받기가
                                        경제적/기술적 사유로 현저히 곤란한 경우
                                    </li>
                                    <li>
                                        -기타 법에 의해 요구된다고 선의로
                                        판단되는 경우 (예. 관련 법령에 의거
                                        적법한 절차에 의한 정부/수사기관의
                                        요청이 있는 경우 등)
                                    </li>
                                    <li>
                                        -서비스 이용에 필요한 목적을 위해
                                        이용자의 개인정보에 대해서 제3자에게
                                        제공(별도 이용자의 동의 여부 확인) *
                                        플랫폼 내 개인정보 제 3자 제공동의 약관
                                        확인
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <p>
                                    사업의 전부 또는 일부 양도, 인수합병 등으로
                                    서비스 제공자의 권리와 의무를 이전 또는
                                    승계하는 경우에는 그 사실을 사전에 상세하게
                                    고지하고, 개인정보 수집/이용 등에 대한 동의
                                    철회의 선택권을 부여합니다. 이용자가
                                    온라인상의 게시판 등을 통해 기재한
                                    인적사항을 제3자가 수집하는 경우가 있을 수
                                    있으므로 이에 유의하시기 바랍니다. 이용자가
                                    스스로 게시판 등을 통해 기재한 인적사항과
                                    관련하여 회사는 어떠한 책임도 지지 않습니다.
                                    개인정보 제3자에게 제공하고 있는 현황은
                                    아래와 같습니다.
                                </p>
                                <div className={styles.sort_table}>
                                    <table>
                                        <caption className="blind">
                                            수집하는 개인정보의 항목
                                        </caption>
                                        <colgroup>
                                            <col style={{ width: "16%" }} />
                                            <col style={{ width: "28%" }} />
                                            <col style={{ width: "28%" }} />
                                            <col style={{ width: "28%" }} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th scope="col">제공받는자</th>
                                                <th scope="col">
                                                    제공받는자의 개인정보
                                                </th>
                                                <th scope="col">
                                                    제공하는 개인정보의 항목
                                                </th>
                                                <th scope="col">
                                                    보유 및 이용기간
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>티맵모빌리티(주)</td>
                                                <td>
                                                    내차사기(TMAP|차란차) 서비스 품질
                                                    향상을 위한 이용내역 및 고객
                                                    정보 기반 데이터 통계/분석
                                                </td>
                                                <td>
                                                    고객명, 암호화된 유저키,
                                                    휴대전화번호, 내차사기(TMAP|차란차)
                                                    서비스 이용기록, 쿠키
                                                </td>
                                                <td>
                                                    회원탈퇴 또는 동의 철회 시
                                                    까지
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </li>
                    {/*4. 개인정보의 취급 위탁*/}
                    <li>
                        <span className={styles.sort}>
                            4. 개인정보의 취급 위탁
                        </span>
                        <p>
                            회사는 서비스 향상을 위해서 필요한 경우 및 기타
                            서비스 제공을 위해서 이용자의 개인정보를 외부에
                            수집·보관·처리·이용·제공·관리·파기 등을 할 수 있도록
                            아래와 같이 업무를 위탁하여 운영하고 있습니다.
                            회사는 위탁계약 등을 통하여 서비스제공자의
                            개인정보보호 관련 지시엄수, 개인정보에 관한
                            비밀유지, 제3자 제공의 금지 및 사고 시의 책임부담
                            등을 명확히 규정하고 당해 계약 내용을 서면 또는
                            전자적으로 보관하여 이용자의 권익을 보호하고
                            있습니다.
                        </p>
                        <div className={styles.sort_table}>
                            <table>
                                <caption className="blind">
                                    개인정보의 취급 위탁
                                </caption>
                                <colgroup>
                                    <col style={{ width: "24%" }} />
                                    <col style={{ width: "38%" }} />
                                    <col style={{ width: "38%" }} />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">위탁 업무 내용</th>
                                        <th scope="col">수탁 업체</th>
                                        <th scope="col">
                                            개인정보의 보유 및 이용기간
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">
                                            안심번호 서비스 운영
                                        </th>
                                        <td>델피콤</td>
                                        <td rowSpan={7}>
                                            회원탈퇴 시 혹은 위탁계약 종료 시
                                            까지
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            클라우드 데이터 보관
                                        </th>
                                        <td>HOSTWAY</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            SMS, 알림톡 발송 업무
                                        </th>
                                        <td>다우기술</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            자동차 이전등록 대행
                                        </th>
                                        <td>(주)오토클릭서비스</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">
                                            자동차 성능점검 진행
                                        </th>
                                        <td>도이치피앤에스(주)</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">홈서비스 상담</th>
                                        <td>(주)팀에이기어</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">전자계약</th>
                                        <td>이폼사인</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </li>
                    {/*5. 개인정보의 보유 및 이용기간*/}
                    <li>
                        <span className={styles.sort}>
                            5. 개인정보의 보유 및 이용기간
                        </span>
                        <p className={styles.sort_para}>
                            회사는 이용자가 회원으로서 서비스를 이용하는 동안
                            이용자의 개인정보를 보유 및 이용하며, 이용자가
                            회원탈퇴를 요청한 경우나 개인정보의 수집 및 이용목적
                            을 달성하거나 보유 및 이용기간이 종료한 경우 또는
                            사업폐지 등의 사유가 발생 한 경우 해당 정보를 지체
                            없이 파기합니다.
                        </p>
                        <p className={styles.sort_para}>
                            회사는 이용자가 회원탈퇴를 요청한 경우 개인정보 도용
                            등으로 인한 원치 않는 회원탈퇴, 회원의 부정이용행위
                            등을 방지하기 위하여 회원탈퇴 신청 후 7일 간
                            개인정보를 보존합니다. 또한 다음의 정보에 대해서는
                            아래의 이유로 명시한 기간 동안 보존합니다.
                        </p>
                        <ul className={styles.list_depth}>
                            <li>
                                <span className={styles.sort}>
                                    가. 회사 내부 방침에 의한 정보 보유 사유
                                </span>
                                <ul>
                                    <li>
                                        <b>부정 이용 기록</b>
                                        <br />
                                        ㄴ보존 이유 : 부정 이용 방지
                                        <br />
                                        ㄴ보존 기간 : 1년
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span className={styles.sort}>
                                    나. 관련 법령에 의한 정보 보유 사유
                                </span>
                                <p>
                                    상법, 전자상거래 등에서의 소비자보호에 관한
                                    법률 등 관계 법령의 규정에 의하여 보존할
                                    필요가 있는 경우 회사는 관계법령에서 정한
                                    일정한 기간 동안 회원정보를 보관합니다. 이
                                    경우 회사는 보관하는 정보를 그 보관의
                                    목적으로만 이용하며 보존기간은 아래와
                                    같습니다.
                                </p>
                                <ul>
                                    <li>
                                        <b>계약 또는 청약철회 등에 관한 기록</b>
                                        <br />
                                        ㄴ보존 이유 : 전자상거래 등에서의
                                        소비자보호에 관한 법률
                                        <br />
                                        ㄴ보존 기간 : 5년
                                    </li>
                                    <li>
                                        <b>
                                            대금결제 및 재화 등의 공급에 관한
                                            기록
                                        </b>
                                        <br />
                                        ㄴ보존 이유 : 전자상거래 등에서의
                                        소비자보호에 관한 법률
                                        <br />
                                        ㄴ보존 기간 : 5년
                                    </li>
                                    <li>
                                        <b>
                                            소비자의 불만 또는 분쟁처리에 관한
                                            기록
                                        </b>
                                        <br />
                                        ㄴ보존 이유 : 전자상거래 등에서의
                                        소비자보호에 관한 법률
                                        <br />
                                        ㄴ보존 기간 : 3년
                                    </li>
                                    <li>
                                        <b>본인확인에 관한 기록</b>
                                        <br />
                                        ㄴ보존 이유 : 정보통신 이용촉진 및
                                        정보보호 등에 관한 법률
                                        <br />
                                        ㄴ보존 기간 : 6개월
                                    </li>
                                    <li>
                                        <b>방문에 관한 기록</b>
                                        <br />
                                        ㄴ보존이유 : 통신 비밀 보호법
                                        <br />
                                        ㄴ보존 기간 : 3개월
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    {/*6. 개인정보 파기절차 및 방법*/}
                    <li>
                        <span className={styles.sort}>
                            6. 개인정보 파기절차 및 방법
                        </span>
                        <p className={styles.sort_para}>
                            회사는 다른 법률에 따라 개인정보를 보존하여야 하는
                            경우가 아닌 한, 수집한 이용자의 개인정보의 수집 및
                            이용목적이 달성되거나, 이용자가 회원탈퇴를 요청한
                            경우 지체 없이 파기하여 향후 어떠한 용도로도 열람
                            또는 이용할 수 없도록 처리합니다. 단, “5.개인정보의
                            보유 및 이용기간 가, 나”와 같은 예외 경우를 두고
                            있습니다. 회사의 개인정보 파기절치 및 방법은 다음과
                            같습니다.
                        </p>
                        <ul className={styles.list_depth}>
                            <li>
                                <span className={styles.sort}>
                                    가. 파기절차
                                </span>
                                이용자가 회원가입 등을 위해 입력한 정보는 목적이
                                달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의
                                서류함) 내부 방침 및 기타 관련 법령에 의한 정보
                                보호 사유에 따라(5. 보유 및 이용기간 참조)일정
                                기간 저장된 후 파기됩니다. 동 개인정보는 법률에
                                의한 경우가 아니고서는 보유되는 이외의 다른
                                목적으로 이용되지 않습니다.
                            </li>
                            <li>
                                <span className={styles.sort}>
                                    나. 파기방법
                                </span>
                                종이에 출력된 개인정보는 분쇄기로 분쇄하거나
                                소각을 통하여 파기하며, 전자적 파일 형태로
                                저장된 개인정보는 기록을 재생할 수 없는 기술적
                                방법을 사용하여 삭제합니다.
                            </li>
                        </ul>
                    </li>
                    {/*7. 만 14세 미만 아동의 개인정보 보호*/}
                    <li>
                        <span className={styles.sort}>
                            7. 만 14세 미만 아동의 개인정보 보호
                        </span>
                        <p>
                            회사가 운영하는 사이트에서는 만14세 미만 아동의 경우
                            회원 가입이 불가능합니다.
                        </p>
                    </li>
                    {/*8. 이용자의 권리와 그 행사 방법*/}
                    <li>
                        <span className={styles.sort}>
                            8. 이용자의 권리와 그 행사 방법
                        </span>
                        <p className={styles.sort_para}>
                            이용자는 언제든지 등록되어 있는 자신의 개인정보를
                            조회하거나 수정할 수 있으며 가입해지(동의철회)를
                            요청할 수도 있습니다. 이용자의 개인정보 조회, 수정을
                            위해서는 '개인정보변경'(또는 '회원정보수정' 등)을,
                            가입해지(티맵 탈퇴 또는 약관동의철회)를 위해서는
                            ‘회원탈퇴’를 클릭하여 본인 확인 절차를 거치신 후
                            직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
                            개인정보관리책임자에게 서면, 전화 또는 이메일로
                            연락하시면 지체 없이 조치하겠습니다.
                        </p>
                        <p className={styles.sort_para}>
                            이용자가 개인정보의 오류에 대한 정정을 요청하신
                            경우에는 정정을 완료하기 전까지 당해 개인정보를 이용
                            또는 제공하지 않습니다. 또한 잘못된 개인정보를
                            제3자에게 이미 제공한 경우에는 정정 처리결과를
                            제3자에게 지체 없이 통지하여 정정이 이루어지도록
                            하겠습니다.
                        </p>
                        <p className={styles.sort_para}>
                            회사는 이용자 혹은 법정 대리인의 요청에 의해 해지
                            또는 삭제된 개인정보는 "5. 개인정보의 보유 및
                            이용기간"에 명시된 바에 따라 처리하고 그 외의 용도
                            로 열람 또는 이용할 수 없도록 처리하고 있습니다.
                        </p>
                    </li>
                    {/*9. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항*/}
                    <li>
                        <span className={styles.sort}>
                            9. 개인정보 자동 수집 장치의 설치/운영 및 거부에
                            관한 사항
                        </span>
                        <p className={styles.sort_para}>
                            회사는 이용자들에게 특화된 맞춤서비스를 제공하기
                            위해서 이용자들의 정보를 저장하고 수시로 불러오는
                            '쿠키(cookie)'를 사용합니다. 쿠키는 웹 서버가 웹
                            브라우저에 보내어 저장했다가 서버의 부가적인 요청이
                            있을 때 다시 서버로 보내주는 문자열 정보를 말합니다.
                        </p>
                        <ul className={styles.list_depth}>
                            <li>
                                <span className={styles.sort}>
                                    가. 쿠키의 사용 목적
                                </span>
                                <p>
                                    이용자들이 방문한 회사의 각 서비스와 웹
                                    사이트들에 대한 로그인 여부 등을 파악하여
                                    이용자에게 최적화된 정보 제공을 위하여
                                    사용합니다.
                                </p>
                            </li>
                            <li>
                                <span className={styles.sort}>
                                    나. 쿠키의 설치/운영 및 거부
                                </span>
                                <p>
                                    이용자는 쿠키 설치에 대한 선택권을 가지고
                                    있습니다. 따라서, 이용자는 웹브라우저에서
                                    옵션을 설정함으로써 모든 쿠키를 허용하거나,
                                    쿠키가 저장될 때 마다 확인을 거치거나,
                                    아니면 모든 쿠키의 저장을 거부할 수도
                                    있습니다. <br />
                                    <br />
                                    쿠키 설정을 거부하는 방법으로는 이용자가
                                    사용하는 웹 브라우저의 옵션을 선택함으로써
                                    모든 쿠키를 허용하거나 쿠키를 저장할 때마다
                                    확인을 거치거나 모든 쿠키의 저장을 거부할 수
                                    있습니다.
                                    <br />
                                    설정방법 : 웹 브라우저 상단의 "도구 &gt;
                                    인터넷 옵션 &gt; 개인정보 &gt; 고급"
                                    <br />
                                    다만, 쿠키의 저장을 거부할 경우에는 로그인이
                                    필요한 회사의 일부 서비스는 이용에 어려움이
                                    있을 수 있습니다.
                                </p>
                            </li>
                        </ul>
                    </li>
                    {/*10. 개인정보의 기술적/관리적 보호 대책*/}
                    <li>
                        <span className={styles.sort}>
                            10. 개인정보의 기술적/관리적 보호 대책
                        </span>
                        <p className={styles.sort_para}>
                            회사는 이용자들의 개인정보를 취급함에 있어
                            개인정보가 분실, 도난, 누출, 변조 또는 훼손되지
                            않도록 안전성 확보를 위하여 다음과 같은
                            기술적/관리적 대책을 강구하고 있습니다.
                        </p>
                        <ul className={styles.list_depth}>
                            <li>
                                <span className={styles.sort}>
                                    가. 개인정보 암호화
                                </span>
                                <p>
                                    이용자의 주요 개인정보는 암호화하여 저장하고
                                    있으며, 파일 및 전송데이터를 암호화하여 혹시
                                    발생할 수 있는 사고 시라도 이용자의
                                    개인정보가 유출되지 않도록 관리되고
                                    있습니다.
                                </p>
                            </li>
                            <li>
                                <span className={styles.sort}>
                                    나. 해킹 등에 대비한 대책
                                </span>
                                <p>
                                    회사는 해킹이나 컴퓨터 바이러스 등에 의해
                                    이용자의 개인정보가 유출되거나 훼손되는 것을
                                    막기 위해 최선을 다하고 있습니다. 개인정보의
                                    훼손에 대비해서 자료를 수시로 백업하고 있고,
                                    최신 백신프로그램을 이용하여 이용자들의
                                    개인정보나 자료가 누출되거나 손상되지 않도록
                                    방지하고 있으며, 암호화 통신 등을 통하여
                                    네트워크상에서 개인정보를 안전하게 전송할 수
                                    있도록 하고 있습니다. 그리고 24시간
                                    침입차단시스템을 이용하여 외부로부터의 무단
                                    접근을 통제하고 있으며, 기타 시스템적으로
                                    보안성을 확보하기 위한 가능한 모든 기술적
                                    장치를 갖추려 노력하고 있습니다.
                                </p>
                            </li>
                            <li>
                                <span className={styles.sort}>
                                    다. 취급 직원의 최소화 및 교육
                                </span>
                                <p>
                                    회사의 개인정보관련 취급 직원은 담당자에
                                    한정시키고 있고 이를 위한 별도의 비밀번호를
                                    부여하여 정기적으로 갱신하고 있으며,
                                    담당자에 대한 수시 교육을 통하여
                                    개인정보취급방침의 준수를 항상 강조하고
                                    있습니다. 또한, 임직원이 이용자의 개인정보를
                                    취급하기 이전에 보안서약서를 통하여 이용자의
                                    개인정보에 대한 정보유출을 사전에 방지하고
                                    관련 사태규정을 마련하여 이에 대한 이행사항
                                    및 준수 여부를 감시하기 위한 내부절차를
                                    마련하고 있습니다.
                                </p>
                            </li>
                            <li>
                                <span className={styles.sort}>
                                    라. 개인정보보호 전담기구의 운영
                                </span>
                                <p>
                                    사내 개인정보보호전담기구 등을 통하여
                                    개인정보취급방침의 이행사항 및 담당자의 준수
                                    여부를 확인하여 문제가 발견될 경우 즉시
                                    수정하고 바로 잡을 수 있도록 노력하고
                                    있습니다.
                                </p>
                            </li>
                        </ul>
                    </li>
                    {/*11. 개인정보 관리 책임자 및 담당자의 연락처*/}
                    <li>
                        <span className={styles.sort}>
                            11. 개인정보 관리 책임자 및 담당자의 연락처
                        </span>
                        <p className={styles.sort_para}>
                            회사는 개인정보에 대한 의견수렴 및 불만처리를
                            담당하는 개인정보 관리책임자 및 담당자를 지정하고
                            있고, 연락처는 아래와 같습니다.
                        </p>
                        <ul>
                            <li>
                                개인정보 관리책임자
                                <br />
                                대표이사 박창우
                                <br />
                                privacy@charancha.com
                                <br />
                                <br />
                                개인정보 관리담당자
                                <br />
                                BackEnd Unit 팀장 김정훈
                                <br />
                                privacy@charancha.com
                                <br />
                                <br />
                                대표번호 : 1533-6452
                            </li>
                        </ul>
                        <br />
                        <p className={styles.sort_para}>
                            귀하께서는 회사의 서비스를 이용하시며 발생하는 모든
                            개인정보보호 관련 민원을 개인정보관리책임자 혹은
                            담당부서로 신고하실 수 있습니다. 회사는 이용자들의
                            신고사항에 대해 신속하게 충분한 답변을 드릴
                            것입니다. 기타 개인정보침해에 대한 신고나 상담이
                            필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
                        </p>
                        <ul className={styles.list_depth}>
                            <li>
                                개인정보 보호 종합지원 포털(안전행정부 운영)
                                <br />
                                <a
                                    onClick={() =>
                                        tmapApp.open({
                                            type: "external",
                                            url: "http://www.privacy.go.kr",
                                            title: "개인정보 보호 종합지원 포털",
                                        })
                                    }
                                >
                                    www.privacy.go.kr
                                </a>{" "}
                                / 02-2100-3343
                            </li>
                            <li>
                                개인정보 침해신고센터 (한국인터넷진흥원 운영)
                                <br />
                                <a
                                    onClick={() =>
                                        tmapApp.open({
                                            type: "external",
                                            url: "http://privacy.kisa.or.kr",
                                            title: "개인정보 침해신고센터",
                                        })
                                    }
                                >
                                    privacy.kisa.or.kr
                                </a>{" "}
                                / (국번없이) 118
                            </li>
                            <li>
                                개인정보보호지원센터 (한국정보화진흥원)
                                <br />
                                <a
                                    onClick={() =>
                                        tmapApp.open({
                                            type: "external",
                                            url: "http://nia.or.kr",
                                            title: "개인정보보호지원센터",
                                        })
                                    }
                                >
                                    nia.or.kr
                                </a>{" "}
                                / 02-2131-0111
                            </li>
                            <li>
                                개인정보분쟁조정위원회
                                <br />
                                <a
                                    onClick={() =>
                                        tmapApp.open({
                                            type: "external",
                                            url: "http://www.kopico.go.kr",
                                            title: "개인정보분쟁조정위원회",
                                        })
                                    }
                                >
                                    www.kopico.go.kr
                                </a>{" "}
                                / 1833-6972
                            </li>
                            <li>
                                대검찰청 사이버수사과
                                <br />
                                <a
                                    onClick={() =>
                                        tmapApp.open({
                                            type: "external",
                                            url: "https://www.spo.go.kr",
                                            title: "대검찰청 사이버수사과",
                                        })
                                    }
                                >
                                    www.spo.go.kr
                                </a>{" "}
                                / (국번없이) 1301
                            </li>
                            <li>
                                경찰청 사이버안전국
                                <br />
                                <a
                                    onClick={() =>
                                        tmapApp.open({
                                            type: "external",
                                            url: "https://cyberbureau.police.go.kr",
                                            title: "경찰청 사이버안전국",
                                        })
                                    }
                                >
                                    cyberbureau.police.go.kr
                                </a>{" "}
                                / 02-3150-2659
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className={styles.sort}>12. 기타</span>
                        <p>
                            회사가 운영하는 사이트에 링크되어 있는 웹사이트들이
                            개인정보를 수집하는 행위에 대해서는 본
                            "개인정보취급방침"이 적용되지 않음을 알려 드립니다.
                        </p>
                    </li>
                    <li>
                        <span className={styles.sort}>13. 고지의 의무</span>
                        <p>
                            현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을
                            시에는 개정 최소 7일 전부터 변경이유 및 내용을
                            홈페이지의 '공지사항'을 통해 고지할 것입니다.
                            <br />본 개인정보취급방침의 내용은 수시로 변경될 수
                            있으므로 사이트를 방문하실 때마다, 이를 확인하시기
                            바랍니다.
                        </p>
                    </li>
                    <li>
                        <span className={cls(styles.sort, "mb0")}>부 칙</span>
                        <p>본 약관은 2024년 5월 13일부터 적용됩니다.</p>
                    </li>
                </ul>
            </div>
        </>
    );
}
