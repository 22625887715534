import { regExp } from "utils/regExp";

export function validAll(values: any) {

    const validArr = [];
    let validResult = false;
    let valid;

    for (let i in values) {
        if (i === 'username') valid = validUsername(values[i])
        else if (i === 'userId') valid = validUserId(values[i])
        else if (i === 'password' || i === 'password') valid = validPassword(values[i])
        else if (i === 'hp') valid = validHp(values[i])
        else if (i === 'email') valid = validEmail(values[i])
        else valid = validEtc(values[i])

        validArr.push(valid);
    }

    validResult = validArr.every((item) => item);

    return validResult;
}

export function validEtc(etc: string) {
    let valid: boolean = false;

    if (etc !== '') {
        valid = true;
    } else {
        valid = false;
    }

    return valid;
}


export function validPassword(password: string) {
    const passwordPattern = new RegExp(regExp.password);
    let valid: boolean = false;

    if (passwordPattern.test(password)) {
        valid = true;
    } else {
        valid = false;
    }

    return valid;
}

export function validNewPassword(password: string, newPassword: string) {
    const passwordPattern = new RegExp(regExp.password);
    let valid: boolean = false;

    if (passwordPattern.test(newPassword) && password === newPassword) {
        valid = true;
    } else {
        valid = false;
    }

    return valid;
}

export function validUsername(username: string) {
    const usernamePattern = new RegExp(regExp.username);
    let valid: boolean = false;

    if (username.trim() !== "" && usernamePattern.test(username)) {
        valid = true;
    } else {
        valid = false;
    }

    return valid;
}

export function validUserId(userId: string) {
    const userIdPattern = new RegExp(regExp.username);
    let valid: boolean = false;

    if (userIdPattern.test(userId)) {
        valid = true;
    } else {
        valid = false;
    }

    return valid;
}

export function validHp(hp: string) {
    const hpPattern = new RegExp(regExp.hp);
    let valid: boolean = false;

    if (hpPattern.test(hp)) {
        valid = true;
    } else {
        valid = false;
    }

    return valid;
}

export function validEmail(email: string) {
    const emailPattern = new RegExp(regExp.email);
    let valid: boolean = false;

    if (emailPattern.test(email)) {
        valid = true;
    } else {
        valid = false;
    }

    return valid;
}


export function errorMsgId(id: string) {
    let errMsg: string = '';

    if (!id) {
        errMsg = "아이디를 입력해 주세요."
    } else if (!validUserId(id)) {
        errMsg = "아이디 형식이 올바르지 않습니다."
    }

    return errMsg;
}

export function errorMsgPwd(password: string) {
    let errMsg: string = '';

    if (!password) {
        errMsg = "비밀번호를 입력해 주세요."
    } else if (!validPassword(password)) {
        errMsg = "비밀번호 형식이 올바르지 않습니다."
    }

    return errMsg;
}

export function errorMsgNewPwd(password: string, newPassword: string) {
    let errMsg: string = '';

    if (!newPassword) {
        errMsg = "비밀번호를 입력해 주세요."
    } else if (!validPassword(newPassword)) {
        errMsg = "비밀번호 형식이 올바르지 않습니다."
    } else if (password !== newPassword) {
        errMsg = "비밀번호가 일치하지 않습니다."
    }

    return errMsg;
}