import React, {useEffect, useRef, useState} from 'react';
import {queryCheck, queryString} from "utils/query";
import {setLocal} from "utils/stroage";
import {RememberId, ShowPassword} from "utils/form";
import {errorMsgId, errorMsgPwd} from "utils/validate";
import domain, {addRedirectUrl} from "utils/domainLink";
import {ErrorMessage, Field, Layout, LoginLink, UseForm} from "components/organisms";
import {BoxButton, ButtonGroup, Divider, Img, Input} from "components/atoms";
import {cls} from "utils/utils";
import styles from "components/organisms/useForm/UseForm.module.scss";

const Login = () => {
    /*** param 로컬스토리지 저장 **/
    const paramRedirectUrl = queryString('redirectUrl').param;
    const redirectUrl = !!paramRedirectUrl ? paramRedirectUrl : '';
    setLocal('redirectUrl', redirectUrl);

    /*** 로그인 실패 **/
    const [loginFail, setLoginState] = useState<boolean>(false);
    const paramLoginState = queryString('loginState').param;
    const handleLoginStateReset = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginState(false);
    }

    useEffect(() => {
        if(!!paramLoginState && paramLoginState === 'fail'){
            setLoginState(true)
        }
    }, []);


    /*** 아이디 저장 **/
    const rememberRef = useRef<HTMLInputElement>(null);
    const {id, isRemember, handleRememberCheck, handleRememberId, handleRememberSave} = RememberId();


    /*** 비밀번호 보기 **/
    const {passwordType, handlePasswordType} = ShowPassword();


    /*** 로그인 유효성 검사 & 폼 전송 **/
    const initialValues = {
        redirectUrl: redirectUrl,
        id: id,
        password: "",
    }
    const handleValidate = (values: any) => {
        const errors = {id: "", password: "",};
        const {id, password} = values;

        errors.id = errorMsgId(id);
        errors.password = errorMsgPwd(password);

        return errors;
    }

    const handleSubmit = () => {
        const form = document.getElementById('form') as HTMLFormElement;

        handleRememberSave();
        form.method = 'POST';
        form.action = `${domain.main}/user/login`;
    }

    return (
        <>
            <Layout
                header={{use: false, logo: false, back: false, close:false, util: false}}
                container={{use: true}}
            >
                <Divider className={cls(styles.header_logo_wrap)}>
                    <h1 className={cls(styles.header_logo)}>
                        <a href={domain.dealerhome} title={'페이지 이동'}>
                            <Img className={styles.logo} src={require(`assets/images/icon/icon_tmap_logo.webp`)} alt={'티맵 | 차란차, TMAP | Deutsch Autoworld'}/>
                            <span className={cls(styles.logo_text, 'caption1_12R')}>TMAP 중고차 광고 등록(딜러용)</span>
                        </a>
                    </h1>
                </Divider>
                <Divider>
                    <UseForm initialValues={initialValues} validate={handleValidate} onSubmit={handleSubmit} id={'form'}>
                        {/* 아이디 */}
                        <Field
                            type="text" name="id" label={'아이디'} ref={rememberRef} placeholder={'아이디를 입력해주세요.'}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                                handleLoginStateReset(e);
                                handleRememberId(e)
                            }}
                        >
                            <ErrorMessage name="id" />
                        </Field>

                        <Field
                            type={passwordType.type} name="password" label={'비밀번호'} placeholder={'8~20자 이내 영문, 숫자, 특수문자 조합'}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                                handleLoginStateReset(e)
                            }}
                        >
                            <ErrorMessage name="password" />
                            {loginFail === true && <p className={cls(styles.field_message, styles.error)}>아이디 또는 비밀번호를 잘못 입력하셨습니다. <br/>5회 이상 잘못 입력시 로그인이 제한됩니다.</p>}

                            <button type={"button"} onClick={handlePasswordType} className={cls(styles.field_icon, styles.field_icon_password, 'icon_in_input')}>
                                <i className={cls('icon_password_eye', 'size_20', passwordType.visible ? 'on' : 'off')}>
                                    <span className='blind'>{passwordType.visible ? '숨기기' : '보이기'}</span>
                                </i>
                            </button>
                        </Field>

                        {/*hidden 값*/}
                        <Field type={'hidden'} name={'redirectUrl'} value={redirectUrl} />

                        <Input
                            type={'checkbox'} label={'아이디저장'} name={'saveId'}
                            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {handleRememberCheck(e)}}
                            checked={isRemember}
                            className={'mn32'}
                        />

                        <ButtonGroup type={'vertical'}>
                            <BoxButton
                                tag={'Button'} type={'submit'} text={'딜러회원 로그인하기'}
                                theme={{fill:'solid', tone: 'primary', size: 'large'}}
                            />
                            <BoxButton
                                tag={'Link'}
                                link={addRedirectUrl(domain.regDealer)}
                                text={'딜러회원 가입하기'}
                                theme={{fill: 'solid', tone:'black', size:'large'}}
                            />
                        </ButtonGroup>

                        <LoginLink
                            register={{
                                use: false,
                            }}
                            findId={{
                                use: true,
                                link: addRedirectUrl(domain.findId)
                            }}
                            findPwd={{
                                use: true,
                                link: addRedirectUrl(domain.findPwd)
                            }}
                        />
                    </UseForm>
                </Divider>
            </Layout>
        </>
    )
}

export default Login;