import React from "react";
import {cls, wordSplit} from "utils/utils";
import styles from "./Notice.module.scss"

/*
* title : 제목
* text  : 서브 텍스트
*/
interface NoticeProps{
    title: string;
    text?: string;
    children?: React.ReactNode;
    [key:string]: any;
}

export default function Notice({
    title,
    text,
    children,
    ...rest
}: NoticeProps){
    return(
        <>
            <div className={styles.notice}>
                <div className={cls(styles.inner)} {...rest}>
                    <i className={cls(styles.icon, 'icon_triangle_bang', 'size_104')}></i>
                    <h2 className={cls(styles.title, 'subtitle_20B')}>{wordSplit(title)}</h2>

                    {text &&
			            <p className={cls(styles.text, 'body3_14R', 'gray_93')}>{wordSplit(text)}</p>
                    }

                    {children}
                </div>
            </div>
        </>
    )
}