/* eslint-disable no-useless-escape */
/***
 * id: 5~15자 이내 영어, 숫자, !@#\.$%^&*_- 만 허용
 * username: 영어, 한글, 숫자, !@#\.$%^&*()_- 만 허용
 * phone: 00000000000 허용,추후에 구분자 필요한 경우 \d 에 삽입
 * email: 처음 특수문자 비허용, 이후 온점(.), 하이픈(_, -)만 허용
 * password: 영문, 숫자, 특수문자, 각 1회 이상 사용하여 8자리 이상 입력
 ***/
export const regExp = {
    id: /^[a-zA-Z0-9!@#\.$%^&*_-]{5,15}$/,
    username: /^[a-zA-Zㄱ-힣0-9!@#\.$%^&*()_-]*$/,
    hp: /^\d{3}\d{3,4}\d{4}$/,
    email: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/,
    password: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[-~!@#$%^&*=+])(?!.*[^a-zA-Z0-9-~!@#$%^&*=+]).{8,20}$/,
    http: /^http[s]?:\/\/([\S]{3,})/i
}