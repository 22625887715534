import React from "react";
import {cls} from "utils/utils";
import styles from './ButtonGroup.module.scss';

/*
* type    : 디자인 가이드 참조
*/
interface ButtonGroupProps {
    type: 'vertical' | 'horizontal' | 'ratio'
    children : React.ReactNode;
}

export default function ButtonGroup({
    type,
    children
}: ButtonGroupProps){
    const clsName = cls(styles.comp, styles[type]);

    return (
        <>
            <div className={clsName}>
                {children}
            </div>
        </>
    )
}