import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { queryString } from "utils/query";
import Login from "pages/auth/login/Login";
import RegisterSocial from "pages/user/register/Social";
import RegisterIntro from "pages/user/register/Intro";
import Privacy from "pages/policy/Privacy";
import Service from "pages/policy/Service";
import TmapPrivacy from "pages/policy/TmapPrivacy";
import TmapService from "pages/policy/TmapService";
import TmapDealerPrivacy from "pages/policy/TmapDealerPrivacy";
import TmapDealerService from "pages/policy/TmapDealerService";
import LoginExceeded from "pages/auth/login/Exceeded";
import FindPwd from "pages/auth/login/FindPwd";
import FindId from "pages/auth/login/FindId";
import FindFail from "pages/auth/login/FindFail";
import domain from "utils/domainLink";
import Health from "pages/actuator/health";
import Redirect from "utils/redirect";

interface PrivateRouteProps {
    access: any;
    Component: any;
}

function PrivateRoute({ access, Component }: PrivateRouteProps) {
    return !!access ? Component : (window.location.href = domain.main);
}

export default function Routers() {
    const access = queryString("id").param;

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route
                        path={domain.policyPrivacy}
                        element={<Privacy />}
                    ></Route>
                    <Route
                        path={domain.policyService}
                        element={<Service />}
                    ></Route>
                    <Route
                        path={domain.policyTmapPrivacy}
                        element={<TmapPrivacy />}
                    ></Route>
                    <Route
                        path={domain.policyTmapService}
                        element={<TmapService />}
                    ></Route>
                    <Route
                        path={domain.policyTmapDealerPrivacy}
                        element={<TmapDealerPrivacy />}
                    ></Route>
                    <Route
                        path={domain.policyTmapDealerService}
                        element={<TmapDealerService />}
                    ></Route>

                    <Route
                        path={domain.regIntro}
                        element={<RegisterIntro />}
                    ></Route>
                    <Route
                        path={domain.regSocial}
                        element={
                            <PrivateRoute
                                access={access}
                                Component={<RegisterSocial />}
                            />
                        }
                    />

                    <Route
                        path={domain.findFail + "/:fail"}
                        element={<FindFail />}
                    ></Route>
                    <Route
                        path={domain.exceeded}
                        element={<LoginExceeded />}
                    ></Route>
                    <Route path={domain.findPwd} element={<FindPwd />}></Route>
                    <Route path={domain.findId} element={<FindId />}></Route>
                    <Route path={domain.login} element={<Login />}></Route>
                    <Route path={domain.health} element={<Health />}></Route>
                    <Route path="/" element={<Redirect />}></Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}
