/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext} from "react";
import {ModalConfirm, ModalContext} from "components/organisms/useModal/UseModal";
import {cls} from "utils/utils";
import styles from "./QnaLink.module.scss"

/* 링크 사용 여부
* alertText     : 텍스트 사용시 '확인' 클릭시 alert 이벤트 생성
* detailText    : 텍스트
* linkText      : 링크 텍스트
* link          : 링크
*/
interface QnaLinkProps{
    alertText?: string;
    detailText: string;
    linkText: string;
    link: string;
}

export default function QnaLink ({
    alertText,
    detailText,
    linkText,
    link
}: QnaLinkProps) {
    const { handleModal, closeModal } = useContext(ModalContext);
    const submitModal = () => {
        closeModal();
        window.location.href = link;
    }

    const handleAlert = (e:React.MouseEvent) => {
        if(!alertText) {
            submitModal();
        }else{
            e.preventDefault();
            handleModal(
                <>
                    <ModalConfirm onClose={closeModal} onSubmit={submitModal}>
                        <p className={'body2_16M'}>{alertText}</p>
                    </ModalConfirm>
                </>
            );
        }
    }

    return (
        <div className={styles.qna}>
            <p className='body3_14R gray_c2'>{detailText}</p>
            <button onClick={handleAlert}  className='body3_14R gray_c2'>
                <span className={styles.qna_text}>{linkText}</span>
                <i className={cls(styles.qna_icon, 'icon_arrow_right_small size_16')}></i>
            </button>
        </div>
    )
}
