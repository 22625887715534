import {useNavigate} from "react-router-dom";
import {cls} from "utils/utils";
import {HeaderProps, ContainerProps, LayoutProps} from "./Lauout.interface";
import styles from "./Layout.module.scss";
import domain from "utils/domainLink";
import {tmapApp} from "utils/tmapApp";

export default function Layout({header, container, className = '', children, style} : LayoutProps) {
    return (
        <>
            <div className={cls(styles.layout, className, header.use ? styles.header_fixed : '', 'layout')} id={'layout'} style={style}>
                {header.use && (<Header {...header}/>)}
                {container.use && <Container {...container} children={children} />}
            </div>
        </>
    );
}

export function Header({use, logo, back, close, util, title, ...rest}: HeaderProps) {
    const navigate = useNavigate();

    const handleBack = () => {
        if(typeof back === "boolean"){
            navigate(-1);
        }else if(typeof back === "string"){
            navigate(back);
        }
    }

    const handleMain = () => {
        window.location.href = domain.main;
    }

    return(
        <>
            <header {...rest} className={cls(styles.header, 'header')}>
                <div className={cls(styles.header_inner)}>
                    {back &&
	                <i className={cls(styles.back, 'icon_header_back')} onClick={handleBack}>
		                <span className={'blind'}>뒤로가기</span>
	                </i>
                    }

                    {close &&
	                <i className={cls(styles.back, 'icon_header_close')} onClick={() => tmapApp.close({ step: -1 })}>
		                <span className={'blind'}>닫기</span>
	                </i>
                    }

                    {logo &&
	                <h1 className={styles.logo} onClick={handleMain}>
		                <i className={'icon_header_logo'}>
			                <span className={'blind'}>차란차</span>
		                </i>
	                </h1>
                    }

                    <h2 className={cls(styles.title, 'body1_18B')}>{title}</h2>
                </div>
            </header>
        </>
    )

}

export function Container({use, children}: ContainerProps){
    return (
        <>
            <section className={cls(styles.container, 'container')}>
                {children}
            </section>
        </>
    )
}