/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';

type StateProps = {
	[key: string]: any;
};

interface UseFormProps {
	initialValues?: any;
	validate?(str: any): any;
	onSubmit?(str: any): any;
}

interface ResultProps {
	name: string;
	type?: string;
	value?: any;
	checked?: any;
	onChange?(e: React.ChangeEvent): void;
	onBlur?(e: React.FocusEvent): void;
}

export default function FormFunction({ initialValues, validate, onSubmit }: UseFormProps) {
	const [values, setValues] = useState<StateProps>(initialValues);
	const [errors, setErrors] = useState<StateProps>({});
	const [touched, setTouched] = useState<StateProps>({});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, dataset } = e.target;
		const inputType = dataset.inputType;

		if (inputType) {
			if (inputType === 'onlyNumber') {
				e.target.value = String(value.replace(/[^0-9]/g, ''));
			}
		}

		setTouched({
			...touched,
			[e.target.name]: false,
		});

		setValues({
			...values,
			[e.target.name]:
				e.target.type !== 'checkbox' && e.target.type !== 'radio'
					? e.target.value
					: (e.target.checked as boolean),
		});
	};

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		setTouched({
			...touched,
			[e.target.name]: true,
		});
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		if (values) {
			setTouched(
				Object.keys(values).reduce((touched: any, field: string) => {
					touched[field] = true;
					return touched;
				}, {}),
			);
		}

		if (validate) {
			const errors = validate(values);

			setErrors(errors);

			if (errors && Object.values(errors)?.some((v) => v)) {
				e.preventDefault();
				return;
			}
		}

		if (onSubmit) {
			onSubmit(values);
		}
	};

	const runValidator = useCallback(() => {
		if (validate) {
			validate(values);
		}
	}, [values]);

	useEffect(() => {
		if (validate) {
			const errors = validate(values);
			setErrors(errors);
		}
	}, [runValidator]);

	const fieldProps = (
		props: any,
		type: string,
		name: string,
		change?: (e: React.ChangeEvent<HTMLInputElement>) => void,
	) => {
		const onBlur = handleBlur;
		const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (!!change) {
				change(e);
			}
			handleChange(e);
		};

		let value = '';

		let result: ResultProps = {
			name,
			type,
			//onBlur,
			onChange,
		};

		if (type !== 'radio') {
			if (type === 'checkbox') {
				value = props.value ? props.value : values[name] || false;
			} else {
				value = props.value ? props.value : values[name];
			}

			result.value = value;
		}

		return result;
	};

	return {
		values,
		errors,
		touched,
		handleChange,
		//handleBlur,
		handleSubmit,
		fieldProps,
	};
}
