import {deviceEnvCheck, deviceCheck} from "utils/device";
import {NavigateFunction} from "react-router-dom";

const {INAPP_TYPE} = deviceEnvCheck();

// export function windowClose() {
//     if(INAPP_TYPE === 'KAKAOTALK') {
//         window.location.href = deviceCheck() ? "kakaoweb://closeBrowser" : "kakaotalk://inappbrowser/close";
//         return;
//     }
//     window.js_web != null ? window.js_web.func_popup_close() : window.close();
// }

// OP21-1584
export function windowClose(navigate: NavigateFunction) {
    try {
        const agent = window.navigator.userAgent || window.navigator.vendor;
        if (agent.toLocaleLowerCase().indexOf("kakaotalk") > -1) {
            // kakao inweb
            window.location.href = /iPad|iPhone|iPod/.test(agent)
                ? "kakaoweb://closeBrowser"
                : "kakaotalk://inappbrowser/close";
        } else if (window.js_web != null) {
            // AOS
            window.js_web.func_popup_close();
        } else if (window.webkit.messageHandlers.js_web != null) {
            // IOS
            window.close();
        } else {
            // ETC
            window.close();
        }
    } catch (err) {
        setTimeout(function () {
            navigate(-2);
            window.open("", "_self")?.close();
        }, 300);
    }
}

/**
 * @param type other 타사링크 | out 일반링크 | outNoHeader 일반링크(app 해더 비노출)
 * @param url
 * @param title
 */
export function openAppPage(type: "out" | "outNoHeader" | "other", url: string, title: string) {
    try {
        if (window.js_web != null) {
            // AOS
            window.js_web.func_link_open(type, url, title);
        } else if (window.webkit.messageHandlers.js_web != null) {
            // IOS
            window.webkit.messageHandlers.js_web.postMessage({
                func_link_open: [type, url, title],
            });
            // window.open(url, title);
        } else {
            // ETC
            window.open(url, title);
        }
    } catch (error) {
        window.open(url, title);
    }
}