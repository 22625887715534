import React from "react";
import {cls} from "utils/utils";
import {UseBottomVar, UseBottomSheet} from "./UseBottomSheet.function";
import styles from "./UseBottomSheet.module.scss";

interface BottomSheetProps {
    open: boolean;
    close: () => void;
    children?: React.ReactNode;
    [key:string] : any
}

export default function BottomSheet({
    open = false,
    close,
    children,
    ...rest
}: BottomSheetProps){

    const { BOTTOM_SHEET_CONT_HEIGHT } = UseBottomVar();
    const { body, handlebar, handleStick, sheet, mask } = UseBottomSheet(open, close);

    return (
        <>
            <div className={cls(styles.comp, open ? styles.open : '')} ref={body}>
                <div className={styles.sheet} ref={sheet} {...rest}>
                    <div className={styles.sheet__header} ref={handlebar}>
                    <span className={styles.handlebar} ref={handleStick}>
                        <span className={styles.bar}></span>
                    </span>
                    </div>
                    <div className={styles.sheet__container} style={{maxHeight: `${BOTTOM_SHEET_CONT_HEIGHT}px`}}>
                        <div className={styles.inner}>
                            {children}
                        </div>
                    </div>
                </div>
                <div className={styles.sheet_mask} ref={mask}></div>
            </div>
        </>
    )
}
