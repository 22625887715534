// get prev url
export function queryPrevURl (){
    const referrer = document.referrer;
    return referrer;
}


// check the query
export function queryCheck (){
    const location = window.location;
    let search = location.search || location.hash
    // const params = new URLSearchParams(search)

    if(search === '') {
        return false;
    }else{
        return true;
    }
}

// get url query-string
export function queryString (param: string = ''){
    const location = window.location;
    let search = location.search || location.hash
    const params = new URLSearchParams(search)

    if(search && params.get(param)){
        return {
            hash: true,
            param: params.get(param)
        };
    }else{
        return {
            hash: false,
        };
    }
}

// make url query-string
export function queryData (data: any){
    const query = Object.keys(data).map((k) =>
        encodeURIComponent(k) + "=" + encodeURIComponent(data[k])
    )
        .join('&');
    return query;
}