/* eslint-disable @typescript-eslint/no-unused-vars */
import request from "utils/oauth";

// COMMON
type Params = any


/**
 * 인증번호 생성 API
 */
interface CreateVerificationNumProps {
    /** 휴대전화번호 */
    hp: string
}
export const onCreateVerificationNum = (params: CreateVerificationNumProps): Promise<any> =>{
    return request(
        {
            method:'POST',
            url: `/user/createVerificationNum`,
            params: params,
        }
    )
}

/**
 * 인증번호 체크 API
 */
interface CheckVerificationNumProps {
    /** 인증번호 생성에서 받은 키값 */
    id: string;
    /** 인증번호 */
    recevingNumber: string;
}
export const onCheckVerificationNum = (params: CheckVerificationNumProps): Promise<any> =>{
    return request(
        {
            method:'HEAD',
            url: `/user/checkVerificationNum`,
            params: params,
        }
    )
}

/**
 * 아이디찾기 리스트 조회 API
 */
interface FindUserListProps {
    /** 이름 */
    userName: string;
    /** hp */
    hp: string;
}
export const onFindUserList = (params: FindUserListProps): Promise<any> =>{
    return request(
        {
            method:'GET',
            url: `/users`,
            params: params,
        }
    )
}

/**
 * 회원 확인 API
 */
interface ExistsUserProps {
    /** 이름 */
    username: string;
    /** hp */
    hp: string;
    /** userId*/
    userId?: string;
}
export const onExistsUser = (params: ExistsUserProps): Promise<any> =>{
    return request(
        {
            method:'HEAD',
            url: `users`,
            params: params,
        }
    )
}

/**
 * 회원 확인 API
 */
interface ExistsUserKeysProps {
    /** 이름 */
    username: string;
    /** hp */
    hp: string;
    /** userId*/
    userId?: string;
}
export const onExistsUserKeys = (params: ExistsUserKeysProps): Promise<any> =>{
    return request(
        {
            method:'GET',
            url: `userKeys`,
            params: params,
        }
    )
}




/**
 * 비밀번호 재설정 API
 */
interface NewPwdProps {
    /** 아이디 */
    userId: string;
    /** 새로운 비밀번호 */
    newPwd: string;
}
export const onNewPwd = (params: NewPwdProps): Promise<any> =>{
    return request(
        {
            method:'PATCH',
            url: `/user/-/newPwd`,
            params: params,
        }
    )
}
