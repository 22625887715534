import React, {useEffect} from 'react';
import 'styles/_common.scss'
import Routers from "./Routers";
import {ModalProvider} from "components/organisms";
import {history} from "utils/history";

function App() {
    function setViewVh() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    useEffect(() => {
        setViewVh();
        window.addEventListener('resize', setViewVh);

        const listenBack = () => {/* back event check*/};
        const unListenBack = history.listen(({ action }) => {
            if (action === "POP") {
                listenBack();
            }
        });

        return () => {
            unListenBack();
            window.removeEventListener('resize', setViewVh);
        }
    }, []);


    return (
        <ModalProvider>
            <Routers />
        </ModalProvider>
    )
}

export default App;
