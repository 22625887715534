import { queryString } from "utils/query";
import { getLocal, setLocal } from "utils/stroage";

interface Props {
    [key: string]: string;
}

const domain: Props = {
    main: `${process.env.REACT_APP_BASIC_URL}`,
    dealerhome: `${process.env.REACT_APP_BASIC_URL}/de/dealerhome`,
    login: `/login`,
    findId: `/login/findId`,
    findPwd: `/login/findPwd`,
    findFail: `/login/fail`,
    exceeded: `/login/exceeded`,
    regIntro: `/register/intro`,
    regSocial: `/register/social`,
    regUser: `${process.env.REACT_APP_BASIC_URL}/user/social/step2?userTypeCd=01`,
    regDealer: `${process.env.REACT_APP_BASIC_URL}/user/social/step2?userTypeCd=02`,
    qna: `${process.env.REACT_APP_BASIC_URL}/cs/qna/writeStep01`,
    policyService: `/policy/service`,
    policyPrivacy: `/policy/privacy`,
    policyTmapService: `/policy/tmapService`,
    policyTmapPrivacy: `/policy/tmapPrivacy`,
    policyTmapDealerPrivacy: `/policy/dealer/tmapPrivacy`,
    policyTmapDealerService: `/policy/dealer/tmapService`,
    health: `/actuator/health`,
};

export const addRedirectUrl = (domain: string, redirectUrl?: string) => {
    const url = redirectUrl || getLocal("redirectUrl") || "";
    return url
        ? `${domain}${domain.includes("?") ? "&" : "?"}redirectUrl=${url}`
        : domain;
};

export default domain;
