import React from "react";
import {Divider} from "components/atoms";
import {Layout} from "components/organisms";
import TmapTermsDealerService from "components/organisms/terms/service/tmapDealerService";

export default function Service(){

    return (
        <>
            <Layout
                header={{use: true, logo: false, back: false, close: true, util: false, title: '이용약관'}}
                container={{use: true}}
            >
                <Divider>
                    <TmapTermsDealerService />
                </Divider>
            </Layout>
        </>
    )
}