import React from "react";
import { cls } from "utils/utils";
import styles from "../Terms.module.scss";

export default function TmapTermsService() {
    return (
        <>
            <div className={cls(styles.terms, 'mt24')}>
                <ul className={styles.list_terms}>
                    {/*제1조*/}
                    <li>
                        <span className={styles.sort}>제1조 (목 적)</span>
                        <p className={styles.sort_para}>
                            이 약관은 서비스 이용자(이하 ‘이용자’)가 차란차 주식회사(이하 ‘회사’)가 티맵모빌리티 주식회사와 업무를 제휴함으로써 운영하는 인터넷 관련 서비스 (‘내차사기(TMAP|차란차)’, 이하 ‘서비스’)를 이용함에 있어 ‘회사’와 이용자와의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
                        </p>
                    </li>
                    {/*제2조*/}
                    <li>
                        <span className={styles.sort}>제 2 조 (정의)</span>
                        <p className={styles.sort_para}> 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                        <ul className={styles.list_depth}>
                            <li>1.‘내차사기(TMAP|차란차)’란 자동차 거래를 위한 온라인상의 인터넷 서비스를 ‘이용자’에게 제공하기 위하여 ‘회사’가 운영하는 가상의 사업장 (‘내차사기(TMAP|차란차)’)를 말하며, 가상의 사업장을 운영하는 사업자의 의미로도 사용합니다.
                            </li>
                            <li>2. ‘서비스’란 구현되는 단말기(PC, TV, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 ‘이용자’가 이용할 수 있는 ‘서비스’ 관련 제반
                                서비스 및 재화의 공급 및 용역이행을 말합니다.
                            </li>
                            <li>3.‘이용자’란 ‘서비스’에 접속하여 이 약관에 따라 ‘회사’가 제공하는 서비스를 받는 자를 말합니다.</li>
                            <li>4.‘채팅’이라 함은 ‘서비스’에서 ‘이용자’에게 제공되는 서비스로써, 차량 구매에 대한 문의 혹은 방송 시청 중에 ‘이용자’가 입력하는 정보를 노출하는
                                것을 말합니다.
                            </li>
                            <li>5.‘결제’라 함은 ‘이용자’가 ‘내차사기(TMAP|차란차)’에 ‘서비스’를 이용하기 위하여 사전에 고지된 금액을 ‘회사’가 제공하는 결제 방식을 통해 구매하는
                                행위를 의미하며, 구체적인 종류와 내용은 “제8조 서비스 이용/이용대금”과 각 구매 및 결제 페이지에서 별도로 안내하고 있습니다.
                            </li>
                        </ul>
                    </li>
                    {/*제3조*/}
                    <li>
                        <span className={styles.sort}>제3조 (약관의 명시와 개정)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘회사’는 이 약관의 내용과 상호, 영업소 소재지, 대표자의 성명, 연락처(전화, 팩스, 전자우편 주소 등) 등을 ‘이용자’가 알 수 있도록 서비스의
                                초기화면에 게시합니다.
                            </li>
                            <li>2.‘회사’는 ‘전자상거래 등에서의 소비자보호에 관한법률’, ‘약관의 규제에 관한법률’, ‘전자문서 및 전자거래 기본법’, ‘전자서명법’, ‘정보통신망
                                이용촉진 및 정보 보호 등에 관한법률’, ‘방문판매 등에 관한법률’, ‘소비자기본법’ 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.’
                            </li>
                            <li>3.'회사'가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 제1항의 방식에 따라 그 개정약관의 적용일자 7일 이전부터 적용일자
                                전일까지 공지하며, ‘이용자’에게 불리하거나 중대한 약관의 개정인 경우에는 그 개정약관의 적용일자 30일 전부터 공지하며, SMS 등으로 ‘이용자’에게 개별
                                통지합니다. 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 ‘전자거래소비자보호지침’ 및 관계법령 또는 상관례에 따릅니다.
                            </li>
                            <li>4.‘회사’가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관
                                조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 ‘이용자’가 개정약관 조항의 적용을 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 ‘회사’에 송신하여
                                ‘회사’의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
                            </li>
                            <li>5.‘이용자’는 변경된 약관 사항에 동의하지 않을 경우 서비스 이용을 중단하고 이용 계약을 해지할 수 있습니다. 다만, ‘이용자’가 전항의 공지기간 이전에
                                이의를 제기하지 않는 경우에는 ‘회사’는 변경된 약관에 동의한 것으로 간주합니다.
                            </li>
                            <li>6.이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 정부가 제정한 ‘전자상거래 등 에서의 소비자보호지침’ 및 관계법령 또는 상관례에 따릅니다
                            </li>
                        </ul>
                    </li>
                    {/*제 4 조*/}
                    <li>
                        <span className={styles.sort}>제 4 조 (서비스의 제공 및 변경)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘회사’는 ‘서비스’에 대해 다음과 같은 업무를 수행합니다.
                                <ul>
                                    <li>①자동차 매매 및 중개 관련 정보 제공 (사진, 문서, 영상 등 다양한 형태의 차량 정보 제공)</li>
                                    <li>②‘이용자’의 자동차 매매 및 중개 과정에서 발생할 수 있는 금융 및 보험 관련 정보의 제공</li>
                                    <li>③‘서비스’의 개발 및 운영</li>
                                    <li>④기타 ‘회사’가 정하는 업무</li>
                                </ul>
                            </li>
                            <li>2.‘회사’는 재화의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화, 용역의 내용을 변경할 수 있습니다. 이 경우에는
                                변경된 재화, 용역의 제공일자를 명시하여 현재의 재화, 용역의 내용을 게시한 곳에 그 제공일자 이전 7일부터 공지합니다.
                            </li>
                            <li>3.‘회사’는 제1항의 서비스 중 일부에 대한 서비스 이용시간을 별도로 정할 수 있으며, 이 경우 그 이용시간을 사전에 ‘이용자’에게 공지 또는
                                통지합니다.
                            </li>
                        </ul>
                    </li>
                    {/*제 5 조*/}
                    <li>
                        <span className={styles.sort}>제 5 조 (서비스의 중단)</span>
                        <ul className={styles.list_depth}>
                            <li>1. ‘회사’는 다음 각호에 해당하는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다.① ‘회사’가 사전에 이용자에게 공지하거나 통지를 한 경우
                                <ul>
                                    <li>② 컴퓨터 등 정보통신설비의 보수점검 교체 및 고장, 통신의 두절 등의 경우</li>
                                    <li>③ 이용자의 폭주로 서비스 제공이 불가능한 경우</li>
                                    <li>④ 설비 등을 긴급복구 하여야 할 경우</li>
                                    <li>⑤ 기간통신사업자가 전기통신서비스를 중지한 경우</li>
                                    <li>⑥ 기타 ‘회사’가 합리적으로 제어할 수 없는 경우 등</li>
                                </ul>
                            </li>
                            <li>2. ‘회사’는 제1항의 사유로 인하여 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, ‘회사’에
                                고의 또는 과실이 없는 경우에는 그러하지 아니합니다.
                            </li>
                        </ul>
                    </li>
                    {/*제 6조*/}
                    <li>
                        <span className={styles.sort}>제 6조 (철회 및 자격상실 등)</span>
                        <ul className={styles.list_depth}>
                            <li>1. '이용자’은 언제든지 ‘회사’에 ‘내차사기(TMAP|차란차)’ 서비스 철회를 요청할 수 있으며 ‘회사’는 즉시 철회 처리합니다.</li>
                            <li>2. 철회처리가 되더라도 이용자가 작성한 게시물은 자동으로 삭제되지 않으므로, 게시물의 삭제를 원하는 경우에는 반드시 탈퇴 전에 삭제해야 합니다.</li>
                            <li>3. ‘이용자’가 다음 각호의 사유에 해당하는 경우, ‘회사’는 이용자격을 철회 및 자격상실을 시킬 수 있습니다.
                                <ul>
                                    <li>①다른 사람의 ‘서비스’이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우</li>
                                    <li>② ‘서비스’를 이용하여 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우</li>
                                </ul>
                            </li>
                            <li>4.‘회사’는 ‘이용자’ 자격을 하기 각 호와 같은 언어폭력 사항이 발생할 경우 제한 또는 정지시킨 후, 동일한 행위가 반복되거나 정당한 사유가 입증되지
                                아니하는 경우 ‘회사’는 이용자격을 영구 상실 시킬 수 있습니다.
                                <ul>
                                    <li>①원치 않는 상대방에게 홍보하는 메시지를 발송하는 경우</li>
                                    <li>②동일한 메시지를 연속으로 보내는 행위</li>
                                    <li>③욕설 및 타인에게 불편함을 줄 수 있는 비방적 메시지</li>
                                    <li>④청소년 유해 채팅 (음란/폭력/혐오/선정성)</li>
                                    <li>⑤타 참여자, 방송진행자, 특정 정치, 인종, 민족, 종교, 성별, 인물 등 타인에 대한 비하/차별/명예훼손 채팅</li>
                                    <li>⑥범죄행위를 목적으로 하거나 이와 관련된 내용을 직/간접적으로 표현하는 언어 사용</li>
                                    <li>⑦특허권, 상표권, 초상권, 저작권 등 타인의 권리를 침해하는 행위</li>
                                    <li>⑧상기 사항 외 타 사용자의 서비스 이용에 불편함을 주는 행위</li>
                                </ul>
                            </li>
                            <li>5.‘회사’가 이용자격을 상실시키는 경우에는 이용자격을 말소합니다. 이 경우 ‘이용자’에게 이를 통지하고, 이용자격 말소 전에 소명할 기회를 부여합니다.
                            </li>
                        </ul>
                    </li>
                    {/*제 7조*/}
                    <li>
                        <span className={styles.sort}>제 7조 (이용자에 대한 통지)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘회사’가 이용자에게 통지를 하는 경우, 내차사기(TMAP|차란차) 서비스의 공지사항으로 안내 할 수 있습니다.</li>
                            <li>2.‘회사’는 불특정다수 이용자에 대한 통지의 경우 1주일이상 ‘서비스’에 게시함으로써 개별 통지에 갈음할 수 있습니다.</li>
                        </ul>
                    </li>
                    {/*제 8조*/}
                    <li>
                        <span className={styles.sort}>제 8조 (서비스 이용/이용대금)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘이용자’가 서비스를 이용하기 위해서는 ‘회사’가 공지사항에 게시하거나 해당서비스 이용안내에서 제시하는 절차를 준수하여야 하며, 해당 서비스의 내용 및
                                책임한계 또한 공지사항에 게시된 내용 및 해당 서비스 이용안내에 따릅니다. 서비스 이용신청은 반드시 실명으로 하여야 합니다.
                            </li>
                            <li>2.서비스 이용대금은 ‘회사’가 공지사항에 게시하거나 해당 서비스 이용안내에서 제시하는 바에 따릅니다. ‘회사’가 제공하는 서비스는 그 이용대금을 별도
                                게시하거나 제시하지 않는 한 무료로 합니다.
                            </li>
                            <li>3.‘회사’는 ‘이용자’가 구매 신청한 재화 또는 용역이 품절 등의 사유로 재화의 인도 또는 용역의 제공을 할 수 없을 때에는 지체없이 그 사유를
                                ‘이용자’에게 통지하고, 사전에 재화 또는 용역의 대금을 받은 경우에는 그 사유 발생일로부터 3일 이내에 계약 해제 및 환급 절차를 취합니다.
                            </li>
                            <li>4.‘회사’가 제공하는 유료 서비스 ‘이용자’ 중 허위매물 등록 등 ‘회사’가 정한 부적절한 이용을 통한 이용 정지시에는 환불되지 않습니다.</li>
                            <li>5.‘회사’가 제공하는 유료 서비스가 천재지변 또는 이에 준하는 불가항력으로 인하여 제공할 수 없는 경우와 ‘이용자’의 과실로 인한 이용 장애 시에는
                                ‘회사’는 유료 서비스 제공에 대한 책임이 면제됩니다.
                            </li>
                            <li>6.‘회사’는 과오금이 발생한 경우 서비스 이용대금의 결제와 동일한 방법으로 과오금 전액을 환불합니다. 다만 동일한 방법으로 환불이 불가능할 때는 이를
                                사전에 고지하고, 이용자가 선택한 방법으로 환급합니다.
                            </li>
                            <li>7.‘회사’는 ‘이용자’가 환불을 희망할 경우 사전에 고지된 약관 및 판매/환불 정책에 의거하여 환불을 진행합니다. 단, 환불수수료가 발생할 수 있으며,
                                이는 ‘회사’가 별도로 안내, 공지하는 내용에 따라 적용하게 되며 다음의 각호의 경우 환불이 불가합니다.
                                <ul>
                                    <li>①이미 사용한 ‘서비스’나 사전 공지한 사용기간이 초과한 ‘서비스’</li>
                                    <li>②‘회사’의 ‘서비스’에서 탈퇴하여 결제에 대한 정보가 없는 경우</li>
                                    <li>③‘이용자’ 본인이 결제한 ‘서비스’가 아닌 타인에게 선물 받거나 양도 받은 ‘서비스’의 경우</li>
                                    <li>④‘회사’에서 ‘서비스’ 결제 시, 환불 불가 상품이라고 사전 고지한 경우</li>
                                </ul>
                            </li>
                            <li>8.‘사용자’는 유료 서비스 구매, 결제, 환불 등의 이의가 있을 경우에는 고객센터를 통해 이의신청을 할 수 있습니다. ‘회사’는 이의신청 접수 후 10
                                영업일 이내 타당성 여부를 조사하여 결과를 ‘이용자’에게 통지합니다.
                            </li>
                            <li>9.기타 본 약관에서 정하지 않은 부분은 전자상거래법 등 관련 법령에서 정하는 바에 의합니다.</li>
                        </ul>
                    </li>
                    {/*제 9조*/}
                    <li>
                        <span className={styles.sort}>제 9조 (서비스 이용계약의 성립)</span>
                        <p className={styles.sort_para}>서비스 이용계약은 ‘이용자’의 서비스 이용신청시 성립합니다. 다만 아래의 경우 ‘회사’는 서비스 이용계약을 취소할 수 있습니다.</p>
                        <ul className={styles.list_depth}>
                            <li>1.신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
                            <li>2.기타 서비스를 제공하는 것이 ‘회사’의 기술상 현저히 지장이 있다고 판단하는 경우</li>
                        </ul>
                    </li>
                    {/*제 10조*/}
                    <li>
                        <span className={styles.sort}>제 10조 (개인정보보호)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘회사’는 ‘이용자’의 정보수집시 구매계약 이행에 필요한 최소한의 정보를 수집합니다.</li>
                            <li>2.‘회사’가 이용자의 개인식별이 가능한 개인정보를 수집한 때에는 반드시 당해 이용자의 동의를 받습니다.</li>
                            <li>3.제공된 개인정보는 당해 이용자의 동의없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 ‘회사’가 집니다. 다만, 다음의 경우에는 예외로 합니다.
                                <ul>
                                    <li>①통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</li>
                                    <li>②관계법령에 의한 경우</li>
                                </ul>
                            </li>
                            <li>4.‘회사’가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리책임자의 신원(소속, 성명 및 전화번호 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공 받는 자, 제공목적 및 제공할 정보의 내용)등 ‘정보통신망이용촉진 및 정보보호 등에 관한’이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.</li>
                            <li>5.‘이용자’는 언제든지 ‘회사’가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 ‘회사’는 이에 대해 지체없이 필요한 조치를 취할 의무를 집니다. ‘이용자’가 오류의 정정을 요구한 경우에는 ‘회사’는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.</li>
                            <li>6.‘회사’는 개인정보 보호를 위하여 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 ‘이용자’의 개인정보의 분실, 도난, 유출, 변조 등 으로 인한 ‘이용자’의 손해에 대하여 모든 책임을 집니다.</li>
                            <li>7.‘회사’ 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체없이 파기합니다.</li>
                        </ul>
                    </li>
                    {/*제 11조*/}
                    <li>
                        <span className={styles.sort}>제 11조 (‘서비스’의 의무와 책임 한계)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘회사’는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는 데
                                최선을 다하여야 합니다.
                            </li>
                            <li>2.‘서비스’에서 제공하는 정보에 대한 신뢰 여부는 전적으로 ‘이용자’ 본인의 책임이며, ‘회사’는 매물정보를 등록한 ‘이용자’에 의한 잘못된 정보 등으로
                                인하여 발생하는 어떠한 직/간접, 부수적, 파생적, 징벌적 손해, 손실, 상해 등에 대하여 도덕적, 법적 책임을 부담하지 않습니다. 또한 ‘서비스’를 통하여
                                노출, 배포, 전송되는 정보를 ‘이용자’가 이용하여 발생하는 중고차 거래 등에 대하여 ‘회사’는 어떠한 도덕적, 법적 책임이나 의무도 부담하지 아니합니다.
                            </li>
                            <li>3.‘회사’는 ‘이용자’가 작성 및 등록 등의 방법으로 ‘서비스’에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며, 이로
                                인해 발생한 ‘이용자’의 물리적, 신체적, 정신적, 금전적 등 손해 일체에 대해서는 어떠한 도덕적, 법적 책임이나 의무도 부담하지 아니합니다.
                            </li>
                        </ul>
                    </li>
                    {/*제 12조*/}
                    <li>
                        <span className={styles.sort}>제12조 (이용자의 의무)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘이용자’는 다음 행위를 하여서는 안되며, 이에 대한 법률적인 책임은 ‘이용자’에게 있습니다.
                                <ul>
                                    <li>①타인의 정보 도용</li>
                                    <li>②‘서비스’에 게시된 정보의 변경</li>
                                    <li>③‘회사’가 정한 정보 이외의 정보(컴퓨터 프로그램 등)를 송신 또는 게시</li>
                                    <li>④‘회사’ 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
                                    <li>⑤‘회사’ 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
                                    <li>⑥외설 또는 폭력적인 메시지,화상,음성 기타 공서양속에 반하는 정보를 ‘서비스’에 공개 또는 게시하는 행위</li>
                                    <li>⑦‘회사’의 동의 없이 영리를 목적으로 ‘서비스’를 사용하는 행위</li>
                                    <li>⑧기타 ‘회사’가 판단하여 제 3자에게 불이익을 제공하는 불법적이거나 부당한 행위</li>
                                </ul>
                            </li>
                            <li>2.‘이용자’는 관계법, 이 약관의 규정, 이용안내 및 ‘서비스’와 관련하여 공지한 주의사항, ‘회사’가 통지하는 사항 등을 준수하여야 하며, 기타
                                ‘회사’의 업무에 방해되는 행위를 하여서는 안 됩니다. 이를 위반 시, “제 6조 철회 및 자격상실 등”에 명시한 이용자격 정지 및 상실 조치가 이뤄질 수
                                있습니다.
                            </li>
                        </ul>
                    </li>
                    {/*제13조*/}
                    <li>
                        <span className={styles.sort}>제 13조 (연결 웹사이트와 피 연결 웹사이트 간의 관계)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘서비스’와 하위 ‘웹사이트’가 하이퍼 링크(예: 하이퍼 링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결
                                웹사이트라고 하고 후자를 피 연결 웹사이트라고 합니다.
                            </li>
                            <li>2.연결 웹사이트는 피 연결 웹사이트가 독자적으로 제공하는 재화 용역에 의하여 ‘이용자’와 행하는 거래에 대해서 보증책임을 지지 않는다는 뜻을 연결
                                웹사이트의 사이트에서 명시한 경우에는 그 거래에 대한 보증책임을 지지 않습니다.
                            </li>
                        </ul>
                    </li>
                    {/*제14조*/}
                    <li>
                        <span className={styles.sort}>제 14조 (‘게시물’의 저작권 및 관리)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘서비스’가 작성한 저작물에 대한 저작권 기타 지적재산권은 ‘서비스’에 귀속합니다.</li>
                            <li>2.‘이용자’는 ‘서비스’를 이용함으로써 얻은 정보를 ‘서비스’의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나
                                제3자에게 제공이 불가하며, 이를 위반할 경우 그 법적 대응 및 결과에 대한 책임을 지게 됩니다.
                            </li>
                            <li>3.‘이용자’가 ‘서비스’ 내에 게시한 ‘게시물’의 저작권은 해당 게시물의 저작자에게 귀속됩니다.</li>
                            <li>4.‘이용자’가 직접 제작하는 게시물(숏폼 영상 및 판매 관련 게시물 등)에 관한 권리(소유권, 지식재산권 등 게시물에 관한 일체의 권리)는 ‘이용자’에게
                                있고, ‘이용자’의 요청에 따라 ‘서비스’가 제작하는 게시물에 관한 권리(소유권, 지식재산권 등 게시물에 관한 일체의 권리)는 서비스’에게 있습니다.
                                ‘이용자’ 및 ‘서비스’의 사전 동의 없이 상대방에게 권리가 귀속된 게시물을 복제, 발행, 배포, 공표, 상업적 이용, 2차적 저작물 작성 등의 방식으로
                                사용해서는 안 됩니다.
                            </li>
                            <li>5.게시물을 제작한 ‘이용자’ 혹은 ‘서비스’와 각 게시물에 연결된 중고차 판매광고의 ‘판매자’는 다를 수 있으며, 게시물에 게재된 정보에 대한 책임은 해당
                                게시물을 제작한 ‘이용자’ 또는 ‘서비스’에 있으며, 중고차 판매광고에 대한 책임은 해당 중고차의 ‘판매자’에게 있습니다.
                            </li>
                            <li>6.‘이용자’가 ‘서비스’ 내에 게시하는 ‘게시물’은 검색결과 내지 ‘서비스’ 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위
                                내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, ‘이용자’는 언제든지 고객센터 또는 ‘서비스’ 내
                                관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.
                            </li>
                            <li>7.‘회사’는 제4항 이외의 방법으로 ‘회사’의 영리를 위하여 ‘이용자’의 ‘게시물’을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에
                                ‘이용자’의 동의를 얻어야 합니다.
                            </li>
                            <li>8.‘회사’는 ‘이용자’가 게시한 게시물 일지라도, ‘서비스’에 피해를 끼치거나, 15조 1항의 사항으로 제한이 느끼다고 판단할 경우 사전 통보 없이 해당
                                게시물을 삭제 혹은 노출중단 할 수 있습니다. ‘이용자’는 이 부분에 대한 정당한 이의제기를 언제든지 할 수 있습니다.
                            </li>
                        </ul>
                    </li>
                    {/*제 15조*/}
                    <li>
                        <span className={styles.sort}>제 15조 (권리의 귀속)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘회사’는 ‘서비스’에 대한 저작권 및 지적재산권은 ‘회사’에 귀속됩니다.</li>
                            <li>2.‘회사’는 서비스와 관련하여 ‘이용자’에게 ‘회사’가 정한 이용조건에 따라 계정, ‘아이디’, 콘텐츠, ‘포인트’ 등을 이용할 수 있는 이용권만을
                                부여하며, ‘이용자’는 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
                            </li>
                        </ul>
                    </li>
                    {/*제 16조*/}
                    <li>
                        <span className={styles.sort}>제 16조 (분쟁처리 및 분쟁조정)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘회사’는 ‘이용자’가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 최선을 다합니다.</li>
                            <li>2.‘회사’는 ‘이용자’로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 ‘이용자’에게 그 사유와
                                처리일정을 통보해 드립니다.
                            </li>
                            <li>3.‘회사’와 ‘이용자’간에 발생한 분쟁은 ‘전자문서 및 전자거래 기본법’ 제32조 및 동 시행령 제16조에 의하여 설치된 전자거래분쟁조정위원회의 조정에
                                따를 수 있습니다.
                            </li>
                        </ul>
                    </li>
                    {/*제 17조*/}
                    <li>
                        <span className={styles.sort}>제 17조 (재판법 및 준거법)</span>
                        <ul className={styles.list_depth}>
                            <li>1.‘회사’와 ‘이용자’간에 발생한 분쟁에 관한 소송은 민사소송법상의 관할법원에 제기합니다. 다만, 서비스 이용 시 관할법원에 대한 별도 합의가 있는
                                경우에는 이에 따릅니다.
                            </li>
                            <li>2.‘회사’와 ‘이용자’간에 제기된 소송에는 한국법을 적용합니다.</li>
                        </ul>
                    </li>

                    {/*부칙*/}
                    <li>
                        <span className={cls(styles.sort, 'mb0')}>부 칙</span>
                        <p className={styles.sort_para}>본 약관은 2024년 5월 13일부터 적용됩니다.</p>
                    </li>
                </ul>
            </div>
        </>
    )
}