/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef, useState } from 'react';
import { cls } from 'utils/utils';
import styles from './Input.module.scss';

/*
 * label : label
 * name  : input name
 * type  : input name
 * order : input type='radio' 인 경우 사용
 */
interface InputProps {
	name?: string;
	type?: string;
	label?: string;
	order?: string;
	className?: string;
	children?: React.ReactNode;
	[key: string]: any;
}

const Input = forwardRef((props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
	if (props.type === 'hidden') {
		return (
			<>
				<HiddenInput {...props} ref={ref} />
			</>
		);
	} else if (props.type === 'checkbox') {
		return (
			<>
				<CheckboxInput {...props} ref={ref} />
			</>
		);
	} else if (props.type === 'radio') {
		return (
			<>
				<RadioInput {...props} ref={ref} />
			</>
		);
	} else if (props.type === 'text' || props.type === 'password' || props.type === 'number' || props.type === 'tel') {
		return (
			<>
				<TextInput {...props} ref={ref} />
			</>
		);
	} else {
		return (
			<>
				<DefaultInput {...props} ref={ref} />
			</>
		);
	}
});

/* input type 별로 정의  */
const HiddenInput = forwardRef((props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
	const { label, order, name, type, className = '', children, ...rest } = props;

	return (
		<>
			<div>
				<input id={name} type={type} name={name} {...rest} />
			</div>
		</>
	);
});
const CheckboxInput = forwardRef((props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
	const { label, order, name, type, className = '', children, ...rest } = props;
	const id = order ? name + order : name;

	return (
		<>
			<div className={cls(styles.input, styles.check_type, className)}>
				<input type="hidden" id={id + 'Yn'} name={id + 'Yn'} {...rest} />
				<input id={id} type={type} name={name} ref={ref} {...rest} />
				{label && (
					<label htmlFor={id} className={'body2_16R'}>
						<span>{label}</span>
					</label>
				)}
				{children}
			</div>
		</>
	);
});
const RadioInput = forwardRef((props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
	const { label, order, name, type, className = '', children, ...rest } = props;
	const id = order ? name + order : name;

	return (
		<>
			<div className={cls(styles.input, styles.radio_type, className)}>
				<input id={id} type={type} name={name} ref={ref} {...rest} />
				{label && (
					<label htmlFor={id} className={'Body2_16M'}>
						<span>{label}</span>
					</label>
				)}
				{children}
			</div>
		</>
	);
});
const TextInput = forwardRef((props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
	const { label, order, name, type = 'text', className = '', children, ...rest } = props;
	const [focus, setFocus] = useState<boolean>(false);

	const onDivFocus = (e: React.FocusEvent<HTMLDivElement>): void => {
		setFocus(true);
	};
	const onDivBlur = (e: React.FocusEvent<HTMLDivElement>): void => {
		setFocus(false);
	};

	const clsName = cls(styles.input, styles.text_type, styles[type], className, focus ? styles.input_focus : '');

	return (
		<>
			<div className={clsName} onFocus={onDivFocus} onBlur={onDivBlur}>
				{label && (
					<label htmlFor={name} className={'caption1_12R'}>
						{label}
					</label>
				)}
				<div className={cls(styles.input_inner)}>
					<input
						id={name}
						type={type}
						name={name}
						ref={ref}
						autoComplete="off"
						className={'body1_18M'}
						{...rest}
					/>
					{children}
				</div>
			</div>
		</>
	);
});
const DefaultInput = forwardRef((props: InputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
	const { children } = props;
	return <>{children}</>;
});

export default Input;
