import React from "react";
import { Divider } from "components/atoms";
import { Layout } from "components/organisms";
import TmapTermsPrivacy from "components/organisms/terms/privacy/tmapPrivacy";

export default function Privacy() {
    return (
        <>
            <Layout
                header={{ use: true, logo: false, back: false, close: true, util: false, title: '개인정보 처리방침' }}
                container={{ use: true }}
            >
                <Divider>
                    <TmapTermsPrivacy />
                </Divider>
            </Layout>
        </>
    )
}