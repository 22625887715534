import React from "react";

export function cls(...classnames: string[]) {
	return classnames.join(" ");
}

export function wordSplit(words: string){
	return (
		words.split("\n").map((word, key) => (<span key={key}>{word}<br /></span>))
	)
}
