import React from "react";
import {cls} from "utils/utils";
import styles from "./Img.module.scss";

/*
* src : 이미지 경로
* alt : 이미지 대체 텍스트
*/
interface ImgProps {
    src: string;
    alt: string;
    children?: React.ReactNode;
    className?: string;
    [key:string]: any
}

export default function Img({
    src,
    alt,
    className = '',
    ...rest
}: ImgProps) {

    const clsName = cls(styles.img, className)

    return (
        <>
            <div {...rest} className={clsName}>
                <img src={src} alt={alt}/>
            </div>
        </>
    );
}