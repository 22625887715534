/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, {useEffect, useRef, useState} from "react";
import {cls} from "utils/utils";
import {Icon} from "components/atoms"
import {Field} from "components/organisms";
import styles from "../Terms.module.scss";

/*
 * setComplete : 부모 component 에서 전달받을 Mutation 함수
 */
interface TermsAllCheckProps {
    setComplete: any;
}

/*
 * id: map id
 * name label, required: input component 에 들어갈 속성
 * group : depth 가 2인 경우 같은 depth 끼리 map 하기 위한 용도 (현재 하드코딩, 추후 개선)
 * link : label 에 내부 링크가 포함되어 상세보기가 있는 경우, 내부링크 입력
 ***/
type DataProps = {
    id: any,
    name: any,
    label: any,
    required?: boolean,
    group?: any,
    link?: any,
    [key: string]: any
}[];

const agreeData:DataProps = [
    {id:0, name:'termsCondi', label:'서비스 이용약관 (필수)', required:true, link:'/policy/service'},
    {id:1, name:'personalInfo', label:'개인정보 수집 이용 동의 (필수)', required:true, link:'/policy/privacy'},
    {id:2, name:'marketingRecv', label:'마케팅 및 광고성 알림 수신 동의 (선택)'},
    {id:3, name:'marketingAppRecv', label:'앱푸시', group: 'marketing'},
    {id:4, name:'marketingTalkRecv', label:'알림톡', group: 'marketing'},
    {id:5, name:'marketingSmsRecv', label:'문자', group: 'marketing'},
]
const marketingData:DataProps = agreeData.filter((elem:any) => { if(elem.group === 'marketing') return true })
const requiredData:DataProps = agreeData.filter((elem:any) => { if(elem.required) return true })

export default function TermsAllCheck(props:TermsAllCheckProps) {
    const ref = useRef<any>([]);

    // 약관 전체 체크 여부
    const [agreeCheckYn, setAgreeCheckYn] = useState<any>(false)

    // 약관 전체 리스트
    const [agreeList, setAgreeList] = useState<DataProps>([])

    // 전체 약관 중 마케팅 리스트
    const [marketingList, setMarketingList] = useState<DataProps>([])

    const handleCheck = (list: {}[], setList: any, checked: boolean, name: any) => {
        if (checked) {
            setList((prev: any) => [...prev, name]);
        } else {
            setList(list.filter((el) => el !== name));
        }
    };

    const handleAllCheck = (data: DataProps, setList: any, checked: boolean) => {
        if(checked) {
            const idArray: DataProps = [];
            data.forEach((item) => idArray.push(item.name));
            setList(idArray);
        }
        else {
            setList([]);
        }
    }

    useEffect(()=>{
        if(ref.current?.every((item: any)=> item.checked === true)){
            setAgreeCheckYn(true)
        }else{
            setAgreeCheckYn(false)
        }

        // 필수 체크
        if(ref.current?.filter((item: any)=> item.required === true && item.checked === true).length === requiredData?.length ){
            props.setComplete(true)
        }else{
            props.setComplete(false)
        }
    }, [agreeList, marketingList])

    return(
        <>
            {/*전체동의*/}
            <div className={cls(styles.input, styles.input_all)}>
                <Field
                    type='checkbox'
                    name='agreeAll'
                    label={'약관 전체동의'}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleAllCheck(agreeData, setAgreeList, e.target.checked);
                        handleAllCheck(marketingData, setMarketingList, e.target.checked);
                    }}
                    checked={
                        agreeCheckYn ? true : false
                    }
                    value={
                        agreeCheckYn ? 'true' : 'false'
                    }
                />
            </div>

            {/*동의*/}
            <div className={styles.form}>
                {agreeData?.map((data, key) => (
                    <div
                        key={key}
                        className={
                            cls(styles.input, styles.check_type,
                            data.link && styles.input_link,
                            data.group && styles.input_sub,
                        )}
                    >
                        <Field
                            type="checkbox"
                            name={data.name}
                            label={data.label}
                            ref={(element) => {
                                ref.current[key] = element;
                            }}
                            required={data.required}
                            onChange={
                                (e: React.ChangeEvent<HTMLInputElement>) => {
                                    if(data.name.includes('marketing')){
                                        if(data.name === 'marketingRecv'){
                                            // 마케팅 전체 동의
                                            handleAllCheck(marketingData, setMarketingList, e.target.checked);
                                            handleCheck(agreeList, setAgreeList, e.target.checked, e.target.name);
                                        }else{
                                            // 마케팅 일부 동의
                                            handleCheck(marketingList, setMarketingList, e.target.checked, e.target.name);
                                        }
                                    }else{
                                        // 일반 일부 동의
                                        handleCheck(agreeList, setAgreeList, e.target.checked, e.target.name);
                                    }
                                }
                            }
                            checked={
                                data.name.includes('marketing') ? (
                                    data.name === 'marketingRecv' ? (
                                        // 마케팅 전체 동의
                                        marketingList.length === marketingData.length ? true : false
                                    ) : (
                                        // 마케팅 일부 동의
                                        marketingList.includes(data.name) ? true : false
                                    )
                                ) : (
                                    // 일반 개별 동의
                                    agreeList.includes(data.name) ? true : false
                                )
                            }
                            value={
                                data.name.includes('marketing') ? (
                                    data.name === 'marketingRecv' ? (
                                        // 마케팅 전체 동의
                                        marketingList.length === marketingData.length ? 'true' : 'false'
                                    ) : (
                                        // 마케팅 일부 동의
                                        marketingList.includes(data.name) ? true : false
                                    )
                                ) : (
                                    // 일반 개별 동의
                                    agreeList.includes(data.name) ? true : false
                                )
                            }
                        >
                            {data.link &&
                                <Icon name={'right'} link={data.link} target="_blank" className={cls(styles.link, 'icon_arrow_right size_16')}/>
                            }
                        </Field>
                    </div>
                ))}
            </div>
        </>
    )

}