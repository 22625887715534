/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link} from "react-router-dom";
import styles from "./Icon.module.scss";
import {regExp} from "utils/regExp";

/*
* type    : 디자인 가이드 참조
* name      : 아이콘 이름
* link      : 내부링크
* text      : 아이콘 텍스트
* className : 추가 클래스
*/
export interface IconProps {
    name: string;
    link?: any;
    text?: string;
    className?: string;
    [key: string]: any;
}

export default function Icon({
    link = '',
    name = '',
    text = '',
    className = '',
    ...rest
} : IconProps) {

    const httpReg = new RegExp(regExp.http);
    const http = httpReg.test(link);

    const handleOnClick = (e: React.MouseEvent) => {
        e.preventDefault();
        window.location.href = link;
    }

    return (
        <>
            {link ? (
                http ? (
                    // 외부링크
                    <a href={"#"} {...rest} className={styles.comp} title={name} onClick={handleOnClick} >
                        <i className={className}></i>
                    </a>
                ) : (
                    // 내부링크
                    <Link to={link} {...rest} className={styles.comp} title={name}>
                        <i className={className}></i>
                    </Link>
                )
            ) : (
                <span className={styles.comp} title={name}>
                    <i className={className}></i>
                </span>
            )}
        </>
    );
}