import React  from "react";
import {BoxButton} from "components/atoms";
import {Layout, Notice, LoginLink} from "components/organisms";
import LoginSocial from "pages/auth/login/Social";
import domain from "utils/domainLink";

export default function LoginExceeded(){
    return (
        <>
            <Layout
                header={{use: true, logo: false, back: domain.login, close:false, util: false, title: '로그인'}}
                container={{use: true}}
            >
                <Notice
                    title={"횟수를 5회 초과했습니다."}
                    text={"개인정보 보호를 위해 비밀번호를 재설정해주세요."}
                >
                    <BoxButton
                        tag={'Link'}
                        link={domain.findPwd}
                        text={'비밀번호 재설정'}
                        theme={{fill:'solid', tone: 'primary', size:'large'}}
                    />
                </Notice>
            </Layout>
        </>
    )
}








