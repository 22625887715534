import React from "react";
import {Divider} from "components/atoms";
import {Layout, TermsService} from "components/organisms";

export default function Service(){

    return (
        <>
            <Layout
                header={{use: true, logo: false, back: false, close: true, util: false, title: '차란차 이용약관'}}
                container={{use: true}}
            >
                <Divider>
                    <TermsService />
                </Divider>
            </Layout>
        </>
    )
}