export const setLocal = (name: string, value: string, option?: any) => {
    return localStorage.setItem(name, value)
}

export const getLocal = (name: string) => {
    return localStorage.getItem(name)
}

export const removeLocal = (name: string) => {
    return localStorage.removeItem(name)
}



