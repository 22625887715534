interface OpenProps {
    type?:
        | "external"
        | "internal"
        | "download"
        | "schemeByURL"
        | "schemeByName";
    url: any;
    title?: string;
    params?: any;
}
interface CloseProps {
    step?: number;
}
interface ExitProps {
    message?: any;
    title?: string;
    confirm?: string;
    cancel?: null | string;
}
interface ToastProps {
    message: string;
}
interface BackLockProps {
    lock: boolean;
}
interface TmapAppProps {
    open: (props: OpenProps) => void;
    close: (props: CloseProps) => void;
    exit: (props: ExitProps) => void;
    toast: (props: ToastProps) => void;
    handleAosBackLock: (props: BackLockProps) => void;
}
interface ParameterProps {
    url: string;
    title?: string;
    bottombar?: "Y" | "N";
    params?: any;
}

const getDomain = () => `${window.location.protocol}//${window.location.host}`;

export const tmapApp: TmapAppProps = (function () {
    const open = ({ type, url, title, params }: OpenProps) => {
        const isAOS = window.TmapPublicApi.env.isAndroid;
        const isIOS = window.TmapPublicApi.env.isIOS;
        const isHTTP = url.includes("http");
        const isSameDomain =
            !isHTTP ||
            url.includes("charancha.com") ||
            url.includes("localhost");

        try {
            if (type === "external") {
                if (!isHTTP) {
                    url = getDomain() + url;
                }

                if (isAOS || isIOS) {
                    window.TmapPublicApi.openBrowser({ url: url });
                } else {
                    window.open(url);
                }
            } else if (type === "internal") {
                if (isSameDomain) {
                    if (isAOS) {
                        window.location.href = url;
                    } else if (isIOS) {
                        window.open(url);
                    } else {
                        window.open(url);
                    }
                } else {
                    if (isAOS || isIOS) {
                        const parameter: ParameterProps = {
                            url: url,
                            title: title,
                            bottombar: "Y",
                        };
                        if (params)
                            parameter.params = encodeURIComponent(
                                JSON.stringify(params)
                            );

                        window.TmapPublicApi.openInAppBrowser(parameter);
                    } else {
                        window.open(url);
                    }
                }
            } else if (type === "download") {
                const downloadURL = url.includes("pdf")
                    ? `https://docs.google.com/gview?embedded=true&url=${url}`
                    : url;
                window.TmapPublicApi.openBrowser({ url: downloadURL });
            } else if (type === "schemeByURL") {
                window.TmapPublicApi.openBrowser({ url: url });
            } else if (type === "schemeByName") {
                if (params.jsonData.extra)
                    params.jsonData.extra = encodeURIComponent(
                        JSON.stringify(params.jsonData.extra)
                    );

                window.TmapPublicApi.openServiceByName(params);
            } else {
                window.open(url);
            }
        } catch (e) {
            window.open(url);
        }
    };

    const close = ({ step = -1 }: CloseProps) => {
        const isAOS = window.TmapPublicApi.env.isAndroid;
        const isIOS = window.TmapPublicApi.env.isIOS;

        try {
            if (isAOS) {
                window.history.go(step);
            } else if (isIOS) {
                window.close();
            } else {
                window.close();
            }
        } catch (e) {
            window.close();
        }
    };

    const exit = ({
        message,
        title,
        confirm = "확인",
        cancel = null,
    }: ExitProps) => {
        const isAOS = window.TmapPublicApi.env.isAndroid;
        const isIOS = window.TmapPublicApi.env.isIOS;

        try {
            let isExit = true;

            if (message) {
                if (isAOS) {
                    window.TmapPublicApi.makeDialogPopup({
                        message,
                        title,
                        confirm,
                        cancel,
                    }).then((value: number) => {
                        // 취소 : 0, 확인 : 1
                        if (value === 1) {
                            // console.log('ok')
                        } else {
                            alert("알 수 없는 오류가 발생했습니다.");
                            isExit = false;
                        }
                    });
                } else if (isIOS) {
                    alert(message);
                } else {
                    alert(message);
                }
            }

            if (isExit) {
                if (isAOS) {
                    window.TmapPublicApi.onBackKeyPressed();
                } else if (isIOS) {
                    window.TmapPublicApi.onBackKeyPressed({ isAnimated: true });
                } else {
                    window.close();
                }
            }
        } catch (e) {
            window.close();
        }
    };

    const toast = ({ message }: ToastProps) => {
        window.TmapPublicApi.makeToast({ msg: message });
    };

    const handleAosBackLock = (lock: BackLockProps) => {
        const isAOS = window.TmapPublicApi.env.isAndroid;

        if (isAOS) {
            window.TmapPublicApi.handleBackKeyEventFromWeb({
                handleFromWeb: lock,
            });
        }
    };

    return {
        open,
        close,
        exit,
        toast,
        handleAosBackLock,
    };
})();
