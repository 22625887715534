import {Link} from "react-router-dom";
import {cls} from "utils/utils";
import domain from "utils/domainLink";
import styles from "./LoginLink.module.scss";

/* 링크 사용 여부
* use1  : 회원가입
* use2  : 아이디 찾기
* use3  : 비밀번호 찾기
*/
interface LinkProps {
    use: boolean,
    link?: any
}
interface LoginLinkProps {
    register: LinkProps;
    findId: LinkProps;
    findPwd: LinkProps;
}

export default function LoginLink({
  register,
  findId,
  findPwd
}: LoginLinkProps) {
    const clsName = cls(styles.links, 'body3_14R', 'gray_55')

    return (
        <>
            <ul className={clsName}>
                {register.use &&
		        <li key='register'>
			        <Link to={register.link || domain.regIntro}>회원가입</Link>
		        </li>
                }
                {findId.use &&
		        <li key='findId'>
			        <Link to={findId.link || domain.findId}>아이디 찾기</Link>
		        </li>
                }
                {findPwd.use &&
		        <li key='findPwd'>
			        <Link to={findPwd.link || domain.findPwd}>비밀번호 찾기</Link>
		        </li>
                }
            </ul>
        </>
    );
}