import React from "react";
import { Divider } from "components/atoms";
import { Layout } from "components/organisms";
import TmapTermsService from "components/organisms/terms/service/tmapService";

export default function Service() {

    return (
        <>
            <Layout
                header={{ use: true, logo: false, back: false, close: true, util: false, title: '이용약관' }}
                container={{ use: true }}
            >
                <Divider>
                    <TmapTermsService />
                </Divider>
            </Layout>
        </>
    )
}