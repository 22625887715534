import React from "react";
import {useParams} from "react-router-dom";
import {BoxButton, ButtonGroup} from "components/atoms";
import {Layout, Notice, BottomFloating} from "components/organisms";
import domain, {addRedirectUrl} from "utils/domainLink";

export default function FindFail (){
    const { fail } = useParams();
    const failTitle = fail === 'id' ? '아이디 찾기' : fail === 'password' ? '비밀번호 찾기' : '찾기'

    return (
        <>
            <Layout
                header={{use: true, logo: false, back: true, close:false, util: false, title: failTitle}}
                container={{use: true}}
            >
                <Notice
                    title={"일치하는 고객 정보가 없습니다."}
                    text={failTitle+"를 재시도하거나\n 회원가입을 통해 차란차의 회원이 되어보세요."}
                />
                <BottomFloating>
                    <ButtonGroup type={'ratio'}>
                        <BoxButton
                            tag={'Link'} link={domain.dealerhome}
                            theme={{fill:'outline', tone: 'primary', size:'large'}}
                        >
                            <i className='icon_home size_20'></i>
                        </BoxButton>
                        <BoxButton tag='Link' link={addRedirectUrl(domain.regIntro)} text='회원가입' theme={{fill: 'solid', tone: 'primary', size: 'large'}}/>
                    </ButtonGroup>
                </BottomFloating>
            </Layout>
        </>
    )
}