import {
    isAndroid,
    isIOS,
    osName,
} from "react-device-detect";

export function deviceEnvCheck(){
    const OS = osName.match(/Mac|PPC/) ? 'MAC' : 'WINDOW'
    const IOS = isIOS;
    const AOS = isAndroid;
    const CHARANCHA = navigator.userAgent.indexOf('CHARANCHA') > -1;

    const INAPP_TYPE_ARR = ['KAKAOTALK','NAVER','Instagram','SamsungBrowser'];
    const INAPP_REGEX_STRING = INAPP_TYPE_ARR.join('|');
    const INAPP_REGEX_PATTERN = new RegExp(INAPP_REGEX_STRING, 'i');
    const INAPP = INAPP_REGEX_PATTERN.test(navigator.userAgent);
    const INAPP_TYPE = INAPP_TYPE_ARR.find(ele => navigator.userAgent.indexOf(ele) > -1);

    return {
        OS,
        IOS,
        AOS,
        CHARANCHA,
        INAPP,
        INAPP_TYPE
    }
}

export function deviceCheck() {
    return (/iPad|iPhone|iPod/.test(navigator.userAgent)) ? true : false;
}