/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef} from "react";
import styles from "components/organisms/useBottomSheet/UseBottomSheet.module.scss";

interface BottomSheetMetrics {
    touchStart: {
        sheetY: any; // 화면 기준, 시트 시작점
        touchY: any; // 화면 기준, 터치 시작점
    },
    touchMove: {
        prevTouchY?: any; // 다음 이벤트 핸들러에서 필요한 터치 포인트 Y값을 저장
        movingDirection: 'none' | 'down' | 'up'; // 화면 움직이는 방향
    }
}

export function UseBottomVar(){
    const MIN_Y = 60;
    const MAX_Y = window.innerHeight - 80;
    const BOTTOM_SHEET_HEIGHT = window.innerHeight - MIN_Y;
    const BOTTOM_SHEET_CONT_HEIGHT = BOTTOM_SHEET_HEIGHT - 52;

    return {
        MIN_Y,
        MAX_Y,
        BOTTOM_SHEET_HEIGHT,
        BOTTOM_SHEET_CONT_HEIGHT
    }
}

export function UseBottomSheet(open:any, close: any){
    //const { MIN_Y, MAX_Y, BOTTOM_SHEET_HEIGHT, BOTTOM_SHEET_CONT_HEIGHT } = UseBottomVar();
    const { MIN_Y, MAX_Y } = UseBottomVar();

    const body = useRef<HTMLDivElement | null>(null)
    const mask = useRef<HTMLDivElement | null>(null)
    const handlebar = useRef<HTMLDivElement | null>(null)
    const handleStick = useRef<HTMLDivElement | null>(null)
    const sheet = useRef<HTMLDivElement | null>(null)
    const metrics = useRef<BottomSheetMetrics>({
        touchStart: {
            sheetY: 0,
            touchY: 0,
        },
        touchMove: {
            prevTouchY: 0,
            movingDirection: 'none'
        }
    })

    // touch event 연결
    useEffect(() => {

        const handleClick = (e: MouseEvent) => {
            e.preventDefault();
            close();
        }

        const handleTouchStart = (e: TouchEvent) => {
            e.preventDefault();
            //const { touchStart, touchMove } = metrics.current;
            const { touchStart } = metrics.current;

            touchStart.sheetY = handlebar.current?.getBoundingClientRect().y;
            touchStart.touchY = e.touches[0].clientY;
        }

        const handleTouchMove = (e: TouchEvent) => {
            e.preventDefault();

            const { touchStart, touchMove } = metrics.current;
            const currentTouch = e.touches[0];

            if (touchMove.prevTouchY !== undefined) {
                touchMove.prevTouchY = touchStart.touchY;
            }

            if (touchMove.prevTouchY < currentTouch.clientY) {
                touchMove.movingDirection = 'down';
            }

            if(touchMove.prevTouchY > currentTouch.clientY){
                touchMove.movingDirection = 'up';
            }

            const touchOffset = currentTouch.clientY - touchStart.touchY;
            let nextSheetY = touchStart.sheetY + touchOffset;


            if (nextSheetY <= MIN_Y) {
                nextSheetY = MIN_Y;
            }

            if (nextSheetY >= MAX_Y) {
                nextSheetY = MAX_Y;
            }

            if(touchMove.movingDirection === 'down'){
                sheet.current?.style.setProperty('transform', `translateY(100%)`);
                mask.current?.style.setProperty('opacity', '0');
            }

        }

        const handleTouchEnd = (e: TouchEvent) => {
            e.preventDefault();
            const { touchMove } = metrics.current;

            const currentSheetY = handlebar.current?.getBoundingClientRect().y;

            if (currentSheetY !== MAX_Y) {
                if (touchMove.movingDirection === 'down') {
                    sheet.current?.style.setProperty('transform', 'translateY(100%)');

                    setTimeout(()=>{
                        sheet.current?.style.setProperty('transform', '');
                        mask.current?.style.setProperty('opacity', '');
                        body.current?.classList.remove(styles.open)
                        close();
                    }, 450)
                }
            }

            metrics.current = {
                touchStart: {
                    sheetY: 0,
                    touchY: 0,
                },
                touchMove: {
                    prevTouchY: 0,
                    movingDirection: "none",
                },
            };
        }

        handlebar.current?.addEventListener('touchstart', handleTouchStart)
        handlebar.current?.addEventListener('touchmove', handleTouchMove)
        handlebar.current?.addEventListener('touchend', handleTouchEnd)
        handleStick.current?.addEventListener('click', handleClick)
        mask.current?.addEventListener('click', handleClick)

        return () => {
            handlebar.current?.removeEventListener('touchstart', handleTouchStart)
            handlebar.current?.removeEventListener('touchmove', handleTouchMove)
            handlebar.current?.removeEventListener('touchend', handleTouchEnd)
            handleStick.current?.removeEventListener('click', handleClick)
            mask.current?.removeEventListener('click', handleClick)
        }

    }, [])

    return (
        { body, handlebar, handleStick, sheet, mask }
    )
}