import { useEffect } from "react";
import domain from "utils/domainLink";

const RedirectComponent = () => {
    useEffect(() => {
        window.location.href = domain.dealerhome;
    }, []);

    return null;
};

export default RedirectComponent;
