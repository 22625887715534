import React, { useState, useEffect } from "react";

const HealthCheck = () => {
    const [isServerUp, setIsServerUp] = useState(true);

    useEffect(() => {
        const checkServerHealth = async () => {
            try {
                const response = await fetch("/login");
                if (response.ok) {
                    setIsServerUp(true);
                } else {
                    setIsServerUp(false);
                }
            } catch (error) {
                setIsServerUp(false);
            }
        };

        const interval = setInterval(checkServerHealth, 5000); // 5초마다 체크

        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            {isServerUp
                ? `{
					"status": 200,
					"message": "OK"
				  }`
                : `{
					"status": 500,
					"message": "Internal Server Error"
				  }`}
        </div>
    );
};

export default HealthCheck;
