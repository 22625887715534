import React, { createContext, forwardRef, useContext, useImperativeHandle } from 'react';
import { Input } from 'components/atoms';
import { cls } from 'utils/utils';
import FormFunction from './useForm.function';
import styles from './UseForm.module.scss';

/*
 * type          : 기본값 = submit, api 전송인 경우 post 사용
 * initialValues : 유효성 검사 대상
 * validate      : 유효성 검사 함수
 * onSubmit      : 제출 함수
 */
interface FormContextProps {
	type?: 'submit' | 'post';
	initialValues?: {};
	validate?(str: {} | any): void;
	onSubmit?(str: {} | any): void;
	className?: string;
	children?: React.ReactNode;
	[key: string]: any;
}

const FormContext = createContext({});

const UseForm = forwardRef(
	(
		{ type = 'submit', children, initialValues, validate, onSubmit, className = '', ...rest }: FormContextProps,
		ref,
	) => {
		const clsName = cls(styles.useform, className);
		const formValue = FormFunction({ initialValues, validate, onSubmit });
		const { values, handleSubmit } = formValue;

		useImperativeHandle(ref, () => ({
			submitForm: handleSubmit, // 부모 컴포넌트에서 호출 가능하게 만듦
		}));

		const postApi = () => {
			if (onSubmit) {
				onSubmit(values);
			}
		};

		return (
			<>
				<FormContext.Provider value={formValue}>
					<form className={clsName} onSubmit={handleSubmit} {...rest}>
						{children}
						{type === 'post' && (
							<button type={'button'} onClick={postApi} id={'postButton'} className={'blind'}>
								<span>post button</span>
							</button>
						)}
					</form>
				</FormContext.Provider>
			</>
		);
	},
);

/* useForm 내부 요소1. 폼 타이틀 */
interface FieldTitleProps {
	children?: React.ReactNode;
	[key: string]: any;
}

const FieldTitle = (props: FieldTitleProps) => {
	return (
		<>
			<div className={cls(styles.field_title)}>{props.children}</div>
		</>
	);
};

/* useForm 내부 요소2. input */
interface FieldProps {
	children?: React.ReactNode;
	[key: string]: any;
}
const Field = forwardRef((props: FieldProps, ref: React.ForwardedRef<HTMLInputElement>) => {
	const { fieldProps } = useContext<FieldProps>(FormContext);

	return (
		<>
			<Input {...props} ref={ref} {...fieldProps(props, props.type, props.name, props.onChange)} />
		</>
	);
});

/* useForm 내부 요소2. 에러메세지 */
interface ErrorMessageProps {
	[key: string]: any;
}
const ErrorMessage = ({ name, ...rest }: ErrorMessageProps) => {
	const { touched, errors } = useContext<ErrorMessageProps>(FormContext);

	if (!touched[name]) {
		return null;
	}

	if (!errors[name]) {
		return null;
	}

	return (
		<>
			<p className={cls(styles.field_message, styles.error)} {...rest}>
				{errors[name]}
			</p>
		</>
	);
};

export { UseForm, FieldTitle, Field, ErrorMessage };
