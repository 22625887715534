/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
import {getLocal, removeLocal, setLocal} from "utils/stroage";

export function RememberId (){
    const [isRemember, setIsRemember] = useState(false);
    const [userid, setUserid] = useState("");
    const localId = getLocal('rememberUserId');
    const id = localId || ''

    const handleRememberCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsRemember(e.target.checked);
    }
    const handleRememberId = (e:React.ChangeEvent<HTMLInputElement>) => {
        setUserid(e.target.value);
    }
    const handleRememberSave = () => {
        if (isRemember) {
            setLocal('rememberUserId', userid)
        }else{
            removeLocal('rememberUserId')
        }
    }

    useEffect(() => {
        if(!!localId) {
            setUserid(localId);
            setIsRemember(true);
        } else{
            setIsRemember(false);
        }
    }, []);

    return {
        id,
        isRemember,
        handleRememberCheck,
        handleRememberId,
        handleRememberSave
    }
}

export function ShowPassword (){
    const [passwordType, setPasswordType] = useState({
        type: 'password',
        visible: false
    })
    const handlePasswordType = () => {
        setPasswordType(() => {
            if (!passwordType.visible) {
                return { type: 'text', visible: true };
            }
            return { type: 'password', visible: false };
        })
    }

    return {
        passwordType,
        handlePasswordType
    }
}

