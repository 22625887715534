/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from "react";

interface ModalFunctionProps{
    maskClose?: boolean;
}

export default function ModalFunction (){
    const [modal, setModal] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<any>(<></>);

    const handleModal = (content: any) => {
        setModal(!modal);
        setModalContent(content);
    }

    const closeModal = (content: any) => {
        setModal(false);
        setModalContent(content);
    }

    const openModal = (content: any) => {
        setModal(true);
        setModalContent(content);
    }

    return {
        modal,
        modalContent,
        handleModal,
        closeModal,
        openModal
    }
}