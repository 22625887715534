import React from "react";
import {Divider} from "components/atoms";
import {Layout} from "components/organisms";
import TmapTermsDealerPrivacy from "components/organisms/terms/privacy/tmapDealerPrivacy";

export default function Privacy(){
    return (
        <>
            <Layout
                header={{use: true, logo: false, back: false, close: true, util: false, title: '개인정보처리방침'}}
                container={{use: true}}
            >
                <Divider>
                    <TmapTermsDealerPrivacy />
                </Divider>
            </Layout>
        </>
    )
}