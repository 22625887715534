import React from "react";
import {cls} from "utils/utils";
import styles from "./Divider.module.scss";

interface DividerProps {
    children?: React.ReactNode;
    className?: string;
    [key:string]: any;
}

export default function Divider({
    children,
    className = '',
    ...rest
}: DividerProps) {

    const clsName = cls(styles.divider, className)

    return (
        <>
            <div {...rest} className={clsName}>
                {children}
            </div>
        </>
    );
}