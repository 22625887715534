import React, { useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { onFindUserList, onExistsUser } from "api/login";
import { BoxButton, ButtonGroup, Divider } from "components/atoms";
import { Field, FieldTitle, UseForm, BottomFloating, Layout, VerificationNum, QnaLink, ModalContext, ModalAlert } from "components/organisms";
import { cls } from "utils/utils";
import domain, { addRedirectUrl } from "utils/domainLink";
import { validUsername } from "utils/validate";
import { wordSplit } from "utils/utils";

import styles from "./FindId.module.scss"

export default function FindId() {
    const navigate = useNavigate();
    const [status, setStatus] = useState<'try' | 'success' | 'fail'>('try');
    const [username, setUsername] = useState<string>('');
    const [verifyValidate, setVerifyValidate] = useState<boolean>(false);
    const [verifyData, setVerifyData] = useState<any>();
    const [findUser, setFindUser] = useState<[]>([]);
    const formValues = { username: '', hp: '', verification: '' };
    const formRef = useRef<any>(null);
    const [hasInput, setHasInput] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
        setHasInput(e.target.value.length > 0);
    };

    const formValidate = (values: any) => {
        return validUsername(values.username);
    }

    const validateInputs = () => {
        if (!username.trim()) {
            clickAlertButton("이름을 입력해주세요.");
            return false;
        }
        if (!validUsername(username)) {
            clickAlertButton("이름에 허용되지 않은 문자 또는 공백이 입력되었습니다. 다시 입력해주세요.");
            return false;
        }
        return true;
    };

    const getUserId = (data: any) => {
        const params = {
            username: data.username,
            hp: verifyData.hp
        }

        onExistsUser(params)
            .then(response => {
                const resourceCount = response.headers['x-resource-count'];

                if (resourceCount === '1') {
                    return onFindUserList(data)
                } else if (resourceCount === '0') {
                    setStatus('fail');
                    navigate(addRedirectUrl(`${domain.findFail}/id`))
                }
            })
            .then(response => {
                if (response.status === 200) {
                    setFindUser(response.data)
                    setStatus('success');
                }
            })
            .catch(error => {
                //console.log(error.response
                // clickAlertButton("서버와 통신 중 문제가 발생했습니다. 다시 시도해주세요.");
            });
    }

    const getUserButton = () => {
        if (!validateInputs()) return;
        formRef.current?.submitForm();
    };

    // 모달
    const alertRef = useRef<HTMLButtonElement>(null);
    const alertMsgRef = useRef<any>('');
    const { handleModal, closeModal } = useContext(ModalContext);

    const clickAlertButton = (msg: string) => {
        alertMsgRef.current = wordSplit(msg);
        alertRef.current!.click();
    };

    const clickAlert = () => {
        handleModal(
            <>
                <ModalAlert onClose={closeModal}>
                    <p className={'body2_16M'}>{alertMsgRef.current}</p>
                </ModalAlert>
            </>
        );
    };

    return (
        <Layout header={{ use: true, logo: false, back: domain.login, close: false, util: false, title: '아이디 찾기' }} container={{ use: true }}>
            {status === 'try' && (
                <>
                    <Divider>
                        <button ref={alertRef} type="button" onClick={clickAlert} className={'blind'}>alert button</button>
                        <UseForm type={'post'} initialValues={formValues} validate={formValidate} onSubmit={getUserId} ref={formRef}>
                            <FieldTitle>
                                <h2 className={'subtitle_20B'}>회원정보를 입력해 주세요</h2>
                            </FieldTitle>

                            <Field type="text" name="username" label='이름' placeholder='이름을 입력해주세요.' value={username} onChange={handleInputChange} />

                            <VerificationNum statusComplete={setVerifyValidate} parentData={setVerifyData} />

                            {/*<QnaLink alertText={'아이디 찾기를 취소하시겠습니까?'} detailText={'궁금하신 사항은 고객센터로 문의해 주세요.'} linkText={'문의하기'} link={domain.qna}/>*/}

                            <BottomFloating>
                                <ButtonGroup type='ratio'>
                                    <BoxButton tag='Link' link={domain.dealerhome} theme={{ fill: 'outline', tone: 'primary', size: 'large' }}>
                                        <i className='icon_home size_20'>home</i>
                                    </BoxButton>
                                    <BoxButton
                                        tag='Button' text='다음' theme={{ fill: 'solid', tone: 'primary', size: 'large' }} onClick={getUserButton} disabled={!hasInput || !verifyValidate} />
                                </ButtonGroup>
                            </BottomFloating>
                        </UseForm>
                    </Divider>
                </>
            )}
            {status === 'success' && (
                <>
                    <Divider>
                        <FieldTitle>
                            <h2 className={'subtitle_20B'}>입력하신 정보와 <br />일치하는 아이디입니다.</h2>
                        </FieldTitle>

                        <SetUserHtml users={findUser} />

                        <QnaLink detailText={'혹시 비밀번호도 잊으셨나요? '} linkText={'비밀번호 찾기'} link={addRedirectUrl(domain.findPwd)} />

                        <BottomFloating>
                            <ButtonGroup type={'ratio'}>
                                <BoxButton tag='Link' link={domain.dealerhome} theme={{ fill: 'outline', tone: 'primary', size: 'large' }}>
                                    <i className='icon_home size_20'>home</i>
                                </BoxButton>
                                <BoxButton tag='Link' link={domain.login} text='로그인' theme={{ fill: 'solid', tone: 'primary', size: 'large' }} />
                            </ButtonGroup>
                        </BottomFloating>
                    </Divider>
                </>
            )}
        </Layout>
    )
}

interface SetUserHtmlProps {
    users: string[] | any[]
}
const SetUserHtml = ({ users }: SetUserHtmlProps) => {
    if (users.length > 0) {
        return (
            <>
                <ul className={styles.list_result}>
                    {users.map(user => (
                        <li key={user.id}>
                            {user.regType === 'google' ? <strong className={cls(styles.name, 'body1_18M')}><i className="icon_social_google shape_round size_24"></i> 구글 간편 가입</strong> :
                                user.regType === 'naver' ? <strong className={cls(styles.name, 'body1_18M')}><i className="icon_social_naver shape_round size_24"></i> 네이버 간편 가입</strong> :
                                    user.regType === 'kakao' ? <strong className={cls(styles.name, 'body1_18M')}><i className="icon_social_kakao shape_round size_24"></i> 카카오 간편 가입</strong> :
                                        user.regType === 'apple' ? <strong className={cls(styles.name, 'body1_18M')}><i className="icon_social_apple shape_round size_24"></i> 애플 간편 가입</strong> :
                                            !user.regType ? <strong className={cls(styles.name, 'subtitle_20B')}>{user.id}</strong> : ''
                            }
                            <p className={cls(styles.detail, 'body2_16R', 'gray_70')}>
                                {(user.regDt).substring(0, 10).replace(/-/g, '.')} {user.userType === '01' ? '일반회원 가입' : user.userType === '02' ? '딜러회원 가입' : ''}
                            </p>
                        </li>
                    ))}
                </ul>
            </>
        )
    } else {
        return (
            <></>
        )
    }
}