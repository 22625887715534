// import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import axios, {AxiosInstance} from "axios";

const request:AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BASIC_URL}`
})

//요청 공통해더
request.defaults.headers.common['Content-type'] = 'application/x-www-form-urlencoded'

//요청 타임아웃
// request.defaults.timeout = 2500;

//요청 인터셉터 추가
request.interceptors.request.use(
    config => {
        return config
    },
    error => {
        //console.log(error) //디버깅
        return Promise.reject(error)
    }
)

//응답 인터센터 추가
request.interceptors.response.use(
    response => {
        const res = response
        return res
    },
    error => {
        //console.log(error) //디버깅
        return Promise.reject(error)
    }

)

export default request;