import React from "react";
import domain, {addRedirectUrl} from "utils/domainLink";
import {BoxButton, ButtonGroup, Divider, Img} from "components/atoms";
import {Layout} from "components/organisms";
import LoginSocial from "pages/auth/login/Social";
import styles from "./Intro.module.scss"

export default function RegisterIntro(){

    return (
        <>
            <Layout
                header={{use: true, logo: false, back: true, close:false, util: false}}
                container={{use: true}}
                className={styles.layout}
            >
                <Divider className={'pt64 pb98'}>
                    <Img src={require('assets/images/icon/icon_slogan.svg').default} alt={'찾는 차 다 있다. 차란차'}/>
                </Divider>
                <Divider>
                    <ButtonGroup type={'vertical'}>
                        {/*<BoxButton*/}
                        {/*    tag={'Link'}*/}
                        {/*    link={domain.regUser}*/}
                        {/*    text={'일반회원 가입하기'}*/}
                        {/*    theme={{fill: 'solid', tone:'primary', size:'large'}}*/}
                        {/*/>*/}
                        <BoxButton
                            tag={'Link'}
                            link={addRedirectUrl(domain.regDealer)}
                            text={'딜러회원 가입하기'}
                            theme={{fill: 'solid', tone:'black', size:'large'}}
                        />
                    </ButtonGroup>
                    {/*<LoginSocial title={'일반회원 간편 가입'}/>*/}
                </Divider>
            </Layout>
        </>
    )
}