import React, {createContext, useContext} from "react";
import {createPortal} from "react-dom";
import ModalFunction from "./UseModal.function";
import styles from "./Modal.module.scss";

/* modal provider */
interface ModalProviderProps {
    children?: any;
}
const ModalContext = createContext<any>('');
const ModalProvider = ({
    children,
}: ModalProviderProps) => {
    const { modal, modalContent, handleModal, closeModal, openModal } = ModalFunction();

    return(
        <>
            <ModalContext.Provider value={{ modal, modalContent, handleModal, closeModal, openModal }}>
                <Modal/>
                {children}
            </ModalContext.Provider>
        </>
    )
}

/* modal context */
interface ModalProps {
    children?: React.ReactNode;
    [key: string]: any;
}
const Modal = () => {
    const root = document.getElementById("root") as HTMLElement;
    const { modal, modalContent } = useContext<ModalProps>(ModalContext);

    if(modal){
        document.body.classList.add('not_scroll');
        return (
            createPortal(
                <>
                    <div className={styles.modal}>
                        {modalContent}
                    </div>
                </>,
                root
            )
        )
    } else {
        document.body.classList.remove('not_scroll');
        return null;
    };
}

/* modal 디자인1 : alert
* onClose : 확인시 발생시킬 이벤트
* */
interface ModalAlertProps {
    onClose?: () => void;
    children: React.ReactNode;
}
const ModalAlert = ({
    onClose,
    children
}: ModalAlertProps) => {
    return (
        <>
            <span className={styles.mask} onClick={onClose}></span>
            <div className={styles.alert}>
                {children}
                <div className={styles.button}>
                    {onClose && <button type={'button'} onClick={onClose} className={'body2_16B primary'}>확인</button>}
                </div>
            </div>
        </>
    )
}

/* modal 디자인2 : confirm
* onClose : 취소시 발생시킬 이벤트
* onClose : 확인시 발생시킬 이벤트
* */
interface ModalConfirmProps {
    onClose?: () => void;
    onSubmit?: () => void;
    children: React.ReactNode;
}
const ModalConfirm = ({
   onClose,
   onSubmit,
   children
}: ModalConfirmProps) => {
    return (
        <>
            <span className={styles.mask} onClick={onClose}></span>
            <div className={styles.alert}>
                {children}
                <div className={styles.button}>
                    {onClose && <button type={'button'} onClick={onClose} className={'body2_16B gray_70'}>취소</button>}
                    {onSubmit && <button type={'button'} onClick={onSubmit} className={'body2_16B primary'}>확인</button>}
                </div>
            </div>
        </>
    )
}

export {ModalProvider, ModalContext, ModalAlert, ModalConfirm}
