/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from "react";

export default function TimerFunction (timeNumber: number){
    const countValid = 5;
    const timeValid = timeNumber;
    const time = useRef<number>(timeValid);

    let intervalRef: {current: NodeJS.Timeout | null} = useRef(null);

    const [min, setMin] = useState<number | string>('');
    const [sec, setSec] = useState<number | string>('');
    const [timerIn, setTimerIn] = useState<'in'|'out'|'ok'|''>(''); // in: 초과X, out: 초과O, ok: 인증완료
    const [timerActive, setTimerActive] = useState<boolean>(true) // 5초 후 재전송 가능

    const timerOff = () => {
        timerReset();
        setTimerIn('ok');
        setMin('');
        setSec('');
    }

    const timerReset = () => {
        clearInterval(intervalRef.current as NodeJS.Timeout);
        time.current = timeValid;

        let m: number | string = Math.floor(timeValid / 60);
        let s: number | string = timeValid % 60;
        m = m < 10 ? "0" + m : m;
        s = s < 10 ? "0" + s : s;

        setMin(m);
        setSec(s);
    };

    const timerClick = () => {
        setTimerActive(false)
        timerReset();
        setTimerIn('in');
        intervalRef.current = setInterval(timeDecrease, 1000);
        return () => clearInterval(intervalRef.current as NodeJS.Timeout);
    };

    useEffect(() => {
        return () => clearInterval(intervalRef.current as NodeJS.Timeout);
    }, []);

    const timeDecrease = () => {
        time.current -= 1;

        let m: number | string = Math.floor(time.current / 60);
        let s: number | string = time.current % 60;
        m = m < 10 ? "0" + m : m;
        s = s < 10 ? "0" + s : s;

        setMin(m);
        setSec(s);
    };

    useEffect(() => {
        if (time.current === timeValid - countValid ) {
            setTimerActive(true)
        }

        if (time.current <= 0) {
            clearInterval(intervalRef.current as NodeJS.Timeout);
            timerOff();
            setTimerIn('out');
        }
    }, [sec]);

    return {
        min,
        sec,
        timerActive,
        timerIn,
        timerOff,
        timerClick,
    }

}