import React, { useContext, useEffect, useRef, useState } from "react";
import { onCheckVerificationNum, onCreateVerificationNum } from "api/login";
import { BoxButton } from "components/atoms"
import { Field, ModalContext, ModalAlert } from "components/organisms";
import { validHp } from "utils/validate";
import { wordSplit } from "utils/utils";
import TimerFunction from "utils/timer";
import styles from "./VerificationNum.module.scss"

/*
* statusComplete    : 부모에 전달받고 전달할 상태값 (해당 컴포넌트 완료 상태값임)
* parentData        : 부모에 전달받고 전달할 데이터
*/
interface VerificationProps {
    statusComplete?: (str: boolean) => void;
    parentData?: any;
}

/* 인증 확인 api req
* hp : 휴대폰번호
* id : 아이디
*/
interface ParmasProps {
    hp: string,
    id: string
}

interface validProps {
    [key: string]: boolean;
}

export default function VerificationNum({
    statusComplete,
    parentData
}: VerificationProps) {
    // 타이머
    const timeValue = TimerFunction(179);
    const { min, sec, timerIn, timerActive, timerOff, timerClick } = timeValue;

    // 버튼 활성화 관련 유효성 검사
    const hpRef = useRef<HTMLInputElement>(null); // 휴대폰 번호
    const verifyRef = useRef<HTMLInputElement>(null); // 인증번호
    const [validate, setValidate] = useState<validProps>({ hp: false, verify: false }); // 휴대폰 번호 유효성 & 인증번호 전송 확인 유무

    const hpValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValidate((prev) => {
            return { ...prev, hp: validHp(hpRef.current!.value || "") };
        })
    }

    // 인증번호 api
    const [apiParams, setApiParams] = useState<ParmasProps>({ hp: '', id: '' });  // api req

    const resetVeirification = (disabeld: boolean) => { // 인증확인 활성화 여부
        if (verifyRef.current) {
            verifyRef.current.disabled = disabeld;
        }
        statusComplete && statusComplete(disabeld);
    }

    const createVerification = () => { // 인증번호 전송
        const params = {
            hp: hpRef.current!.value
        }

        resetVeirification(false);

        onCreateVerificationNum(params)
            .then(response => {
                // 재전송 클릭 방지
                if (!timerActive) {
                    clickAlertButton('재 전송은 5초가 지난 후에 가능합니다.');
                    return;
                }

                // 타이머 시작
                clickAlertButton('인증번호가 발급 되었습니다.');
                timerClick();

                setApiParams((prev) => {
                    return { ...prev, id: response.data.id, hp: params.hp }
                });
                setValidate((prev) => { // 인증번호 전송 완료 -> 인증확인 버튼 확성화
                    return { ...prev, verify: true }
                })
                parentData && parentData({ hp: params.hp }); // 부모 컴포넌트에 전달할 값
            })
            .catch(error => {
                clickAlertButton("예기치 않은 오류가 발생했습니다. 다시 시도해 주세요.");
            });
    }

    const checkVerification = () => { // 인증 확인
        const params = {
            id: apiParams.id,
            recevingNumber: verifyRef.current!.value
        }

        onCheckVerificationNum(params)
            .then(response => {
                const resourceCount = response.headers['x-resource-count'];

                if (resourceCount === '1') {
                    // 타이머 끄기
                    timerOff();
                    resetVeirification(true);
                } else if (resourceCount === '0') {
                    clickAlertButton('인증번호가 일치하지 않습니다.');
                }
            })
            .catch(error => {
                clickAlertButton("인증번호 확인 중 문제가 발생했습니다. 다시 시도해 주세요.");
            });
    }

    // 모달
    const alertRef = useRef<HTMLButtonElement>(null);
    const alertMsgRef = useRef<any>('')
    const { handleModal, closeModal } = useContext(ModalContext);

    const clickAlertButton = (msg: string) => {
        verifyRef.current!.disabled = false;
        alertMsgRef.current = wordSplit(msg);
        alertRef.current!.click();
    }

    const clickAlert = () => {
        handleModal(
            <>
                <ModalAlert onClose={closeModal}>
                    <p className={'body2_16M'}>{alertMsgRef.current}</p>
                </ModalAlert>
            </>
        );
    }

    useEffect(() => {
        if (timerIn === 'out') {
            clickAlertButton('입력 시간이 지났습니다.');

            if (verifyRef.current) {
                verifyRef.current.disabled = true;
            }
            return;
        }

        if (timerIn === "in") {
            if (verifyRef.current) {
                verifyRef.current.disabled = false;
            }
        }
    }, [timerIn])

    return (
        <>
            <button ref={alertRef} type="button" onClick={clickAlert} className={'blind'}>alert button</button>

            <Field onChange={hpValue} type="tel" pattern="\d*" ref={hpRef} maxLength='11' name="hp" label={'휴대폰번호'} placeholder={'휴대폰번호를 입력해주세요.'} data-input-type='onlyNumber'>
                <BoxButton
                    tag={'Button'}
                    text={validate.verify ? '인증번호 재전송' : '인증번호 전송'}
                    theme={{ fill: 'solid', tone: 'primary', size: 'medium' }}
                    onClick={createVerification}
                    disabled={validate.hp ? false : true}
                />
            </Field>

            <Field type="tel" pattern="\d*" ref={verifyRef} maxLength="6" name="verification" placeholder={'인증번호 입력'} data-input-type='onlyNumber'>
                {timerIn === 'in' && <span className={styles.span_in_input}>{min}:{sec}</span>}
                <BoxButton
                    tag={'Button'}
                    text={timerIn === 'ok' ? '인증 완료' : '인증 확인'}
                    theme={{ fill: 'solid', tone: 'primary', size: 'medium' }}
                    onClick={checkVerification}
                    disabled={validate.verify && timerIn === 'in' ? false : true}
                />
            </Field>
        </>
    )

}