/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";
import styles from "./BottomFloating.module.scss";

interface BottomFloatingProps {
    children: React.ReactNode;
}

export default function BottomFloating(props: BottomFloatingProps) {
    const [height, setHeight] = useState<any>('');
    const ref = useRef<HTMLDivElement | null>(null);
    const layout = document.getElementById('layout');

    useEffect(() => {
        setHeight(ref.current?.clientHeight);
        !!layout && layout.setAttribute('style', `padding-bottom: ${height}px`)
    }, [height])

    return(
        <div ref={ref} className={styles.bottom_floating}>
            {props.children}
        </div>
    )
}