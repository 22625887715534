import React, {useState} from "react";
import {BoxButton, ButtonGroup} from "components/atoms";
import {BottomFloating, ErrorMessage, Field, FieldTitle, UseForm} from "components/organisms";

import domain from "utils/domainLink";
import {validNewPassword, validPassword} from "utils/validate";

/*
* userKey   : 유저키, 비밀번호 재설정시 req 로 필요
*/
interface ChangePwdProps {
    userKey: string;
}

export default function ChangePwd({
    userKey,
}: ChangePwdProps){

    // 유효성 검사
    const [validate, setValidate] = useState<boolean>(false);
    const initialValues = {userKey: userKey, pwd: '', newPwd:''}

    const handleValidate = (values: any) => {
        const errors = {pwd: '', newPwd: '',};
        const {pwd, newPwd} = values;

        setValidate(validPassword(pwd) && validNewPassword(pwd, newPwd));

        return errors;
    }

    // form 전송
    const handleSubmit = () => {
        const form = document.getElementById('form') as HTMLFormElement;
        form.method = "POST"
        form.action = `${domain.main}/user/-/newPwd`;
    }

    return (
        <>
            <UseForm initialValues={initialValues} validate={handleValidate} onSubmit={handleSubmit} id={'form'}>
                <FieldTitle>
                    <h2 className={'subtitle_20B'}>새로운 비밀번호를 설정해 주세요</h2>
                </FieldTitle>

                {/* 비밀번호 */}
                <Field type="password" name="pwd" label={'비밀번호'} placeholder={'8~20자 이내 영문, 숫자, 특수문자 조합'}>
                    <ErrorMessage name="pwd" />
                </Field>

                {/* 비밀번호 확인 */}
                <Field type="password" name="newPwd" label={'비밀번호 확인'} placeholder={'8~20자 이내 영문, 숫자, 특수문자 조합'}>
                    <ErrorMessage name="newPwd" />
                </Field>

                <Field type={'hidden'} name={'userKey'} value={userKey} />

                {/*비밀번호 찾기*/}
                <BottomFloating>
                    <ButtonGroup type={'ratio'}>
                        <BoxButton
                            tag='Link'
                            link={domain.dealerhome}
                            theme={{fill: 'outline', tone: 'primary', size: 'large'}}
                        >
                            <i className={'icon_home size_20'}>home</i>
                        </BoxButton>
                        <BoxButton
                            tag={'Button'} type={'submit'} text={'확인'}
                            theme={{fill:'solid', tone: 'primary', size:'large'}}
                            disabled={validate ? false : true}
                        />
                    </ButtonGroup>
                </BottomFloating>
            </UseForm>
        </>
    )
}








