import React, { useState, useRef, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import { onExistsUserKeys } from "api/login";
import { BoxButton, ButtonGroup, Divider } from "components/atoms";
import { ChangePwd, Field, FieldTitle, UseForm, BottomFloating, Layout, VerificationNum, ModalContext, ModalAlert } from "components/organisms";
import { validUsername, validUserId } from "utils/validate";
import domain, { addRedirectUrl } from "utils/domainLink";
import { wordSplit } from "utils/utils";

export default function FindPwd() {
    // const navigate = useNavigate();
    const [status, setStatus] = useState<'try' | 'success' | 'fail'>('try');
    const [username, setUsername] = useState<string>('');
    const [userId, setUserId] = useState<string>('');
    const [verifyValidate, setVerifyValidate] = useState<boolean>(false);
    const [verifyData, setVerifyData] = useState<any>();
    const [userKey, setUserKey] = useState<string>('');
    const formValues = { username: '', userId: '', hp: '', verification: '' };
    const formRef = useRef<any>(null);
    const [hasInput, setHasInput] = useState<boolean>(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: 'username' | 'userId') => {
        if (field === 'username') {
            setUsername(e.target.value);
        } else {
            setUserId(e.target.value);
        }
        setHasInput(e.target.value.length > 0 && (username.length > 0 || userId.length > 0));
    };

    const formValidate = (values: any) => {
        return validUsername(values.username) && validUserId(values.userId);
    };

    const validateInputs = () => {
        if (!username.trim()) {
            clickAlertButton("이름을 입력해주세요.");
            return false;
        }
        if (!userId.trim()) {
            clickAlertButton("아이디를 입력해주세요.");
            return false;
        }
        if (!validUsername(username)) {
            clickAlertButton("이름에 허용되지 않은 문자 또는 공백이 입력되었습니다. 다시 입력해주세요.");
            return false;
        }
        if (!validUserId(userId)) {
            clickAlertButton("아이디에 허용되지 않은 문자 또는 공백이 입력되었습니다. 다시 입력해주세요.");
            return false;
        }
        return true;
    }

    const getUserPassword = (data: any) => {
        const params = {
            userId: data.userId,
            username: data.username,
            hp: verifyData.hp
        }

        onExistsUserKeys(params)
            .then(response => {
                if (response.status === 200) {
                    setUserKey(response.data);
                    setStatus('success');
                } else {
                    setStatus('fail');
                    window.location.href = addRedirectUrl(`${domain.findFail}/password`);
                }
            })
            .catch(error => {
                // console.log(error.response)
            });
    }

    const getUserButton = () => {
        if (!validateInputs()) return;
        formRef.current?.submitForm();
    };

    // 모달
    const alertRef = useRef<HTMLButtonElement>(null);
    const alertMsgRef = useRef<any>('');
    const { handleModal, closeModal } = useContext(ModalContext);

    const clickAlertButton = (msg: string) => {
        alertMsgRef.current = wordSplit(msg);
        alertRef.current!.click();
    };

    const clickAlert = () => {
        handleModal(
            <>
                <ModalAlert onClose={closeModal}>
                    <p className={'body2_16M'}>{alertMsgRef.current}</p>
                </ModalAlert>
            </>
        );
    };

    return (
        <>
            <Layout header={{ use: true, logo: false, back: true, close: false, util: false, title: '비밀번호 찾기' }} container={{ use: true }}>
                {status === 'try' && (
                    <>
                        <Divider>
                            <button ref={alertRef} type="button" onClick={clickAlert} className={'blind'}>alert button</button>
                            <UseForm type={'post'} initialValues={formValues} validate={formValidate} onSubmit={getUserPassword} ref={formRef}>
                                <FieldTitle>
                                    <h2 className={'subtitle_20B'}>회원정보를 입력해 주세요</h2>
                                </FieldTitle>

                                <Field type="text" name="username" label={'이름'} placeholder={'이름을 입력해주세요.'} value={username} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, 'username')} />
                                <Field type="text" name="userId" label={'아이디'} placeholder={'5~15자 이내 영어, 숫자, 특수문자 조합'} value={userId} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, 'userId')} />
                                <p className={'caption1_12R gray_c2'}>
                                    <i className={'icon_circle_bang size_16'}></i> 특수문자는 ~ ! @ # $ % ^ & * - = _ + 만 허용합니다.
                                </p>

                                <VerificationNum statusComplete={setVerifyValidate} parentData={setVerifyData} />
                                {/*<QnaLink alertText={'비밀번호 찾기를 취소하시겠습니까?'} detailText={'궁금하신 사항은 고객센터로 문의해 주세요.'} linkText={'문의하기'} link={domain.qna}/>*/}

                                <BottomFloating>
                                    <ButtonGroup type={'ratio'}>
                                        <BoxButton tag={'Link'} link={domain.dealerhome} text={''} theme={{ fill: 'outline', tone: 'primary', size: 'large' }}>
                                            <i className={'icon_home size_20'}>test</i>
                                        </BoxButton>
                                        <BoxButton tag={'Button'} text={'다음'} theme={{ fill: 'solid', tone: 'primary', size: 'large' }} onClick={getUserButton} disabled={!hasInput || !verifyValidate} />
                                    </ButtonGroup>
                                </BottomFloating>
                            </UseForm>
                        </Divider>
                    </>
                )}
                {status === 'success' && (
                    <>
                        <Divider>
                            <ChangePwd userKey={userKey} />
                            <div className={'mt-24'}>
                                <p className={'caption1_12R gray_c2'}>
                                    <i className={'icon_circle_bang size_16'}></i> 특수문자는 ~ ! @ # $ % ^ & * - = _ + 만 허용합니다.
                                </p>
                            </div>
                        </Divider>
                    </>
                )}
            </Layout>
        </>
    )
}








