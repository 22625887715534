import React from "react";
import {Link} from "react-router-dom";
import {regExp} from "utils/regExp";
import styles from "./BoxButton.module.scss";

/* 버튼 디자인 공통 속성
* 디자인 가이드 참조
* */
interface ThemeProps {
    fill: 'solid' | 'outline';
    tone: 'primary' | 'secondary' | 'gray' | 'white' | 'black';
    size: 'tiny' | 'small' | 'medium' | 'large' | 'xLarge';
}

/* 버튼 타입
* type    : 버튼 타입
* text    : 버튼 텍스트
* theme   : 버튼 디자인
*/
interface DefaultButtonProps {
    tag: 'Button';
    type?: 'submit' | 'button';
    text?: string;
    theme: ThemeProps;
    children?: React.ReactNode;
    [key:string]: any;
}
function DefaultButton(props: DefaultButtonProps){
    const clsName = `${styles.comp} ${styles[props.theme.fill]} ${styles[props.theme.tone]} ${styles[props.theme.size]}`;
    const fontthemeName = props.theme.size === 'tiny' ? 'caption2_10M' :
        props.theme.size === 'small' ? 'caption1_12M' :
            props.theme.size === 'medium' ? 'body3_14M' :
                props.theme.size === 'large' ? 'body2_16M' :
                    props.theme.size === 'xLarge' ? 'body1_18M' : '';

    return (
        <>
            <button type={props.type ? props.type : 'button'} className={clsName} disabled={props.disabled} onClick={props.onClick}>
                {props.text && <span className={fontthemeName}>{props.text}</span>}
                {props.children}
            </button>
        </>
    )
}


/* 링크 타입
* link    : 링크(외부, 내부링크 상관X)
* text    : 버튼 텍스트
* theme   : 버튼 디자인
*/
interface LinkButtonProps {
    tag: 'Link';
    link: string;
    text?: string;
    theme: ThemeProps;
    children?: React.ReactNode;
    [key:string]: any;
}
function LinkButton(props: LinkButtonProps){
    const httpReg = new RegExp(regExp.http);
    const http = httpReg.test(props.link);
    const clsName = `${styles.comp} ${styles[props.theme.fill]} ${styles[props.theme.tone]} ${styles[props.theme.size]}`
    const fontthemeName = props.theme.size === 'tiny' ? 'caption2_10M' :
                        props.theme.size === 'small' ? 'caption1_12M' :
                        props.theme.size === 'medium' ? 'body3_14M' :
                        props.theme.size === 'large' ? 'body2_16M' :
                        props.theme.size === 'xLarge' ? 'body1_18M' : '';

    return (
        <>
            {http === true ? (
                <a href={props.link} className={clsName}>
                    {props.text && <span className={fontthemeName}>{props.text}</span>}
                    {props.children}
                </a>
            ) : (
                <Link to={props.link} className={clsName}>
                    {props.text && <span className={fontthemeName}>{props.text}</span>}
                    {props.children}
                </Link>
            )}
        </>
    )
}


/*
* 버튼 : 버튼 타입 | 링크 타입
* */
type ButtonProps = DefaultButtonProps | LinkButtonProps;
function isType(tag: ButtonProps): tag is DefaultButtonProps{
    return tag.tag === 'Button';
}
export default function BoxButton(tag: ButtonProps){
    if(isType(tag)){
        return (
            <>
                <DefaultButton {...tag}/>
            </>
        )
    }

    return (
        <>
            <LinkButton {...tag}/>
        </>
    )
}